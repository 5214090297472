import React from 'react'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import Login from './Login'
import Public from './Public'
import Recovery from './Recovery'
import Reset from './Reset'
import ResetResult from './ResetResult'
import Main from './Main'
import useLocalStorage from '../hooks/useLocalStorage'
import { logout } from '../firebase/firebase-service'

const App = () => {
  const [, setRedirect] = useLocalStorage("redirect", null)

  const validateToken = (location) => {
    const userValid = Cookies.get('user') ? true : false

    if (!userValid && location?.pathname) {
      setRedirect(location.pathname)
    }

    if (location?.search) {
      const urlParams = new URLSearchParams(location.search);
      const organization = urlParams.get('organization')
      const event = urlParams.get('event')

      if (organization) {
        Cookies.set('organization', organization, { expires: 7 })
      }

      if (event) {
        Cookies.set('event', event, { expires: 7 })
      }
    }

    if (!userValid) {
      logout()
    }

    return userValid
  }

  return (
    <>
      <Helmet>
        <title>{process.env.REACT_APP_ENVIRONMENT?.toLowerCase() !== 'production' ? `${process.env.REACT_APP_ENVIRONMENT?.slice(0, 3).toUpperCase()} - ` : ''}AT Manager</title>
        <link rel="stylesheet" href="/revolvapp/css/revolvapp.min.css" />
      </Helmet>
      <Router basename="/">
        <Route
          path="/public"
          render={({ location }) => (<Public />)}
        />
        <Route
          exact
          path="/"
          render={({ location }) => (validateToken(location) ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Redirect to="/login" />
          ))}
        />
        <Route
          path="/login"
          render={({ location }) => (validateToken(location) ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Login />
          ))}
        />
        <Route
          path="/recovery"
          render={({ location }) => (validateToken(location) ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Recovery />
          ))}
        />
        <Route
          path="/reset"
          render={({ location }) => (validateToken(location) ? (
            <Redirect to={`/admin/`} />
          ) : (
            <Reset />
          ))}
        />
        <Route
          path="/result"
          render={({ location }) => (validateToken(location) ? (
            <Redirect to={`/admin/`} />
          ) : (
            <ResetResult />
          ))}
        />
        <Route
          path="/admin"
          render={({ location }) => (validateToken(location) ? (
            <Main />
          ) : (
            <Redirect to={{ to: '/', state: { from: location } }} />
          ))}
        />
      </Router>
    </>
  )
}

export default App
