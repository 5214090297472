import React, { useEffect, useState } from 'react'
import defaultValue from '../Lib/defaultValue'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import Select from 'react-select';
import Button from '../Button'
import { useSession } from '../../contexts/AuthContext'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

const RulesZone = ({
  storedData,
  onChange: updateStored,
  name,
  translatable,
  options: { label },
  readMode = false,
  timezone,
}) => {
  
  const [updateOnlyTicketZone, setUpdateOnlyTicketZone] = useState(true)
  const [ticketZones, setTicketZones] = useState()
  const [value, setValue] = useState(
    Object.values(defaultValue(name, storedData, translatable, [])).map(
      m => {
        if (typeof m.key === 'undefined') m.key = generateToken(30)
        return m
      },
    ),
  )
  const token = useSession()


  const getTicketZones = (type) => {
    const bodyData = {}
    if (storedData && storedData.ticketType) {
      bodyData.ticketType = storedData.ticketType
    }

    const event = Cookies.get('event')
    if (event) {
      bodyData.event = event
    }

    if (!event && !storedData.ticketType) {
      return
    }

    fetch(`${process.env.REACT_APP_API_URL}zone-rules-ticket`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {

          let rawTicketZones = response.body.zones
          if (rawTicketZones.length === undefined) {
            rawTicketZones = [rawTicketZones]
          }
          setTicketZones(rawTicketZones)
          setUpdateOnlyTicketZone(false)
          if (type === 'addModel') {
            addModel(rawTicketZones)
          }
        } else {
          if (response.status === 202 && response.body.error === 'NO_TICKET_ZONES') {
            setTicketZones()
            if (type === 'addModel') {
              addModel(false)
            }
          }
        }
      })
  }


  const onChange = (event, key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        if (event.target.name === 'startDate' || event.target.name === 'endDate') {
          item[event.target.name] = dayjs.tz(event.target.value, timezone).utc().toISOString()
        } else {
          item[event.target.name] = event.target.value
        }
      }
      return item
    })
    setValue(rawValue)
  }

  const shouldAddMultipleValue = (eventValue, key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        const v = eventValue
        let val = item['eventlocationzone']
        if (!val || !val.length) val = []

        if (val.length === 0 || (val.length > 0 && !val.some(item => item === v))) {
          val.push(v)
        }
        item['eventlocationzone'] = val
      }
      return item
    })
    setValue(rawValue)
  }

  useEffect(() => {
    updateStored(name, value)
  }, [value])

  const handleAddAllItems = (key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        item['eventlocationzone'] = ticketZones.map(i => i._id)
      }
      return item
    })
    setValue(rawValue)
  }

  const handleRemoveAllItems = (key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        item['eventlocationzone'] = []
      }
      return item
    })
    setValue(rawValue)
  }


  const ListItem = ({ item }) => (
    <div className="w-full p-4 my-2 rounded bg-grayLight" key={item.key}>
      <div className="flex w-full mb-4">
        <div className="flex flex-col pr-4">
          <p className="pr-4">Type</p>
          <select
            name="type"
            type="text"
            placeholder="Type"
            value={item.type}
            onChange={(event) => onChange(event, item.key)}
            className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray`}
          >
            <option value="include">Include</option>
            <option value="exclude">Exclude</option>
          </select>
        </div>
        <div className="flex flex-col pr-4">
          <p className="pr-4">Start Date</p>
          <input
            name="startDate"
            className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
            type="datetime-local"
            placeholder={label}
            value={dayjs.tz(item.startDate, 'UTC').tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(event) => onChange(event, item.key)}
          />
        </div>
        <div className="flex flex-col pr-4">
          <p className="pr-4">End Date</p>
          <input
            name="endDate"
            className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
            type="datetime-local"
            placeholder={label}
            value={dayjs.tz(item.endDate, 'UTC').tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(event) => onChange(event, item.key)}
          />
        </div>
        <span className="pl-1 ml-auto cursor-pointer" onClick={() => removeRule(item.key)}>
          ✕
        </span>
      </div>
      {ticketZones &&
        <div className="flex w-full">
          <div className="flex flex-col w-full pr-4">
            <p className="pr-4">Zones</p>
            {ticketZones && item.eventlocationzone && item.eventlocationzone?.length > 0 && ticketZones.length > 0 && (
              <div className="flex flex-wrap mt-2">
                {item.eventlocationzone
                  .map(id => id && id.length > 0 && multipleOption(ticketZones.find(item => item._id === id), item.key, 'eventlocationzone'))}
              </div>
            )}
            <Select
              isSearchable
              placeholder="Select Zone"
              closeMenuOnSelect={false}
              value={-1}
              onChange={(selected) => {
                shouldAddMultipleValue(selected.value, item.key)
              }}
              options={[...ticketZones.map(i => ({ value: i._id, label: i.title }))]}
              className="text-sm"
              isClearable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: '#FFC4AD',
                  primary50: '#ffb293',
                  primary: '#0300c9',
                },
                input: (styles) => ({ ...styles, borderColor: 'red' }),
              })}
              styles={{
                control: (styles) => ({ ...styles, borderColor: "#eee", minHeight: '20px' }),
                valueContainer: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
                input: (styles) => ({ ...styles, marginTop: 2, marginBottom: 1 }),
                dropdownIndicator: (styles) => ({ ...styles, padding: 2 }),
                clearIndicator: (styles) => ({ ...styles, padding: 2 }),
                menuList: (styles) => ({ ...styles, paddingTop: 0, paddingBottom: 0 }),
              }}
            />
            <div className='flex mt-2'>
              <Button onClick={() => handleAddAllItems(item.key)} color="primary" size="small" className='mx-0'>Select all zones</Button>
              <Button onClick={() => handleRemoveAllItems(item.key)} color="orange" size="small">Remove all zones</Button>
            </div>
          </div>
        </div>
      }
    </div>
  )

  const removeRule = (key) => {
    const rawValue = [...value]
    setValue(rawValue.filter(item => item.key !== key))
  }

  const multipleOption = (option, key, type) => {
    if (option) {
      return (
        <div
          key={option._id}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${option._id}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(option._id, key, type)}>
            ✕
          </span>
        </div>
      )
    }
  }

  const removeItem = (id, key, type) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        item[type] = item[type].filter(item => item !== id)
      }
      return item
    })
    setValue(rawValue)
  }

  const List = ({ items }) => {
    const renderItems = []
    items.map((item, index) => {
      return renderItems.push(ListItem({ key: index, item: item }))
    })
    return renderItems
  }

  const addAction = async () => {
    if (readMode) return
    if (!ticketZones) {
      getTicketZones('addModel')
    } else {
      addModel()
    }
  }

  useEffect(() => {
    if (updateOnlyTicketZone) {
      getTicketZones('firstRender')
    }
  }, [])

  useEffect(() => {
    if (!updateOnlyTicketZone) {
      addModel()
    }
  }, [ticketZones])

  const addModel = async (ticketZonesFallback) => {
    const ticketZonesArray = ticketZones || ticketZonesFallback

    const today = dayjs().tz(timezone)
    const startDate = (today.hour() < 6) ?
      today.subtract(1, 'day').hour(4).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
      :
      today.hour(4).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')

    const endDate = (today.hour() < 8) ?
      today.hour(4).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')
      :
      today.add(1, 'day').hour(4).minute(0).second(0).format('YYYY-MM-DDTHH:mm:ss')

    if (ticketZonesArray && ticketZonesArray.length > 0) {
      let event = false
      if (Array.isArray(ticketZonesArray)) {
        event = ticketZonesArray[0].event
        event = ticketZonesArray.find(item => item.event).event
      } else {
        event = ticketZonesArray.event[0]
      }

      setValue([
        ...value,
        {
          key: generateToken(30),
          type: 'include',
          startDate: startDate,
          endDate: endDate,
          eventlocationzone: false
        },
      ])
    } else {
      setValue([
        ...value,
        {
          key: generateToken(30),
          type: 'include',
          startDate: startDate,
          endDate: endDate,
          eventlocationzone: false
        },
      ])
    }
  }

  if (storedData) {
    return (
      <div>
        <div className="flex flex-row items-end justify-between">
          <label>{label}</label>
          <Button onClick={() => addAction()} color="primary" size="small" className='mx-0'>Add rule</Button>
        </div>
        { value.length ?
          List({ items: value }) :
          <div className="w-full px-4 py-2 my-2 text-xs rounded bg-grayLight">
            No specific zones rules
          </div>
        }
      </div>
    )
  } else {
    return false
  }

}

export default RulesZone
