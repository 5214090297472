import React, { useState, useEffect, useRef } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import debounce from 'lodash/debounce'
import Button from '../Button'
import { useConfig } from '../Store'
import dayjs from 'dayjs'
import useSessionPermissions from '../../hooks/useSessionPermissions'
import InvitationModal from '../InvitationModal'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const Wristband = () => {
  const appConfig = useConfig()
  const title = 'Wristband codes'
  const history = useHistory()
  const { page: pageParam, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const modelName = 'wristband'
  const [data, setData] = useState(false)
  const [page, setPage] = useState(pageParam - 1 || false)
  const pageLimit = 50
  const [searchValue, setSearchValue] = useState('')
  const [managerComment, setManagerComment] = useState('')
  const [wristbandCode, setWristbandCode] = useState('')
  const [wristbandCodeToSave, setWristbandCodeToSave] = useState('')
  const [qrReaderMode, setQrReaderMode] = useState(true)
  const [wristBandCodeError, setWristBandCodeError] = useState(false)
  const [wristBandCodeNoAlphanumeriError, setWristBandCodeNoAlphanumeriError] = useState(false)
  const [oldData, setOldData] = useState({ managerComment: '', wristbandCode: '' })
  const [resultMessage, setResultMessage] = useState('')
  const [lastEditInfo, setLastEditInfo] = useState()
  const { loading, role, setLoading } = useSessionPermissions(false, 'wristband', 'model-list')
  const [showModal, setShowModal] = useState(false)
  const [prefix, setPrefix] = useState()
  const handleShowModal = () => setShowModal(state => !state)
  const token = useSession()


  const inputTicket = useRef(null);
  const inputWristbandcode = useRef(null);

  const debounced = useRef(debounce((fn, value) => fn(value), 300))

  const getData = (value) => {    
    setData(false)
    if (!value) {
      inputTicket.current.focus()
      return
    }

    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}wristband-code-generator`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'wristband',
        page,
        pageLimit,
        ticketId: value.trim(),
        event: getEvent(),
        organization: getOrganization(),
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200) {
          setResultMessage('')
          const rawBody = { ...response.body }
          if (rawBody.item && rawBody.item.managerComment) {
            setManagerComment(rawBody.item.managerComment)
          }
          if (rawBody.item && rawBody.item.wristbandCode) {
            setWristbandCode(rawBody.item.wristbandCode)
          }
          setData(rawBody.item)
          setOldData({
            managerComment: rawBody.item.managerComment,
            wristbandCode: rawBody.item.wristbandCode,
          })

          if (rawBody.lastEditInfo) {
            setLastEditInfo(rawBody.lastEditInfo)
          } else {
            setLastEditInfo(null)
          }
        } else {
          setResultMessage('No ticket found')
          if (qrReaderMode) {
            inputTicket.current.select()
          }
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const resetData = () => {
    setWristBandCodeError(false)
    setSearchValue('')
    setData(false)
    setManagerComment('')
    setWristbandCode('')
    inputTicket.current.focus()
  }

  const updateData = () => {
    if (wristbandCodeToSave.match(/[^a-zA-Z0-9]/g)) {
      setWristBandCodeNoAlphanumeriError(true)
      return
    }
    setWristBandCodeError(false)
    if (loading) {
      return
    }

    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}wristband-code-generator`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'wristband',
        page,
        pageLimit,
        ticketId: searchValue.trim(),
        editData: true,
        managerComment,
        wristbandCode: wristbandCodeToSave,
        _id: data._id,
        oldData,
        event: getEvent(),
        organization: getOrganization(),
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          if (response.body.updated) {
            resetData()
            setTimeout(() => {
              setLoading(false)
              setResultMessage('Ticket updated')
              inputTicket.current.focus()
              setTimeout(() => {
                setResultMessage('')
              }, 3500);
            }, 500);
          }
        } else {
          setLoading(false)
          setResultMessage('Error')
          setTimeout(() => {
            setResultMessage('')
          }, 2000);
          console.log('Error', response.status, response.body.error)
          if (response.status === 400 && response.body.error === 'ERROR_WRISTBAND_CODE_ALREADY_EXISTS') {
            setWristBandCodeError(true)
          }
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleChangeSearch(event)
    }
  }

  const handleChangeSearch = (event) => {
    let rawSearchValue = event.target.value
    if (rawSearchValue.indexOf('acst://t/') !== -1) {
      rawSearchValue = rawSearchValue.replace('acst://t/', '')
    }
    setSearchValue(rawSearchValue)
  }

  useEffect(() => {
    inputTicket.current.focus()
  }, [])

  useEffect(() => {
    debounced.current(getData, searchValue)
  }, [searchValue])

  useEffect(() => {
    if (data) {
      inputWristbandcode.current.focus()
    }
  }, [data])


  const handleChangeWristband = (code) => {
    setWristBandCodeNoAlphanumeriError(false)
    if (code === 'OKOK') {
      updateData()
      setTimeout(() => {
        setWristbandCode(wristbandCodeToSave)
      }, 500);
    } else if (code === 'CLOSE') {
      resetData()
    } else if (inputWristbandcode.current) {
      setWristbandCodeToSave(code)
      if (qrReaderMode) {
        setTimeout(() => {
          if (inputWristbandcode.current) {
            inputWristbandcode.current.select()
          }
        }, 500);
      }
    }
  }

  const generateRandomWristbandCode = () => {
    const randomCode = Math.random().toString(36).substring(2, 12).toUpperCase()
    // add prefix
    if (prefix) {
      setWristbandCodeToSave(`${prefix}.${randomCode}`)
      setWristbandCode(`${prefix}.${randomCode}`)
    } else {
      setWristbandCodeToSave(randomCode)
      setWristbandCode(randomCode)
    }
  }

  const handlePrefix = (prefix) => {
    setPrefix(prefix)
    if (prefix) {
      const cleanWristbandCode = wristbandCode.indexOf('.') !== -1 ? wristbandCode.split('.')[1] : wristbandCode
      setWristbandCodeToSave(`${prefix}.${cleanWristbandCode}`)
      setWristbandCode(`${prefix}.${cleanWristbandCode}`)
    }
  }

  useEffect(() => {
    debounced.current(handleChangeWristband, wristbandCode)
  }, [wristbandCode])


  const switchQrReaderMode = () => {
    setQrReaderMode(!qrReaderMode)
    inputTicket.current.select()
  }

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className='flex justify-between'>
        <div>
          <div className="flex items-center justify-between">
            <h2 className="text-xl wght-semibold">{title}</h2>
            {showModal &&
              <InvitationModal token={token} handleShowModal={handleShowModal} modelName={modelName} event={event} />
            }
          </div>

          {
            role &&
            <div className='flex'>
              <div className="relative flex text-xs text-gray wght-light">
                <div>
                  <span className='wght-semibold'>{role.toUpperCase()}</span>
                </div>
              </div>
            </div>
          }
        </div>
        <div>
          <Button visibility={role === 'admin'} size="small" onClick={handleShowModal} color='success'>Share</Button>
        </div>
      </div>

      <div className="w-full mt-8 mb-2 sm:mb-0 sm:w-auto">
        <div className='flex justify-between'>
          <p className='wght-semibold'>Ticket</p>

        </div>
        <div className="flex flex-col md:flex-row">
          <input
            ref={inputTicket}
            name="search"
            className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
            type="text"
            placeholder="Id"
            value={searchValue}
            onChange={handleChangeSearch}
            onKeyDown={(e) => handleKeyDown(e)}
            onFocus={(event) => event.target.select()}
          />
        </div>
      </div>

      {loading ?
        <div className="mt-8">Loading...</div> :
        <>
          {!data ?
            <div className='mt-8'>{resultMessage}</div>
            :
            <>
              <div className='p-2 mt-8 bg-white rounded'>
                <div className='flex flex-col md:flex-row'>
                  {data.validationImage &&
                    <div className='w-1/6 mb-2'>
                      <img className='h-full rounded' src={data.validationImage} />
                    </div>
                  }
                  <div className='mx-4'>
                    <div className="flex items-center mb-2">
                      <span className='wght-semibold'>First name:&nbsp;</span><span>{data.infoFirstName || data.firstName}</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <span className='wght-semibold'>Last name:&nbsp;</span><span>{data.infoLastName || data.lastName}</span>
                    </div>
                    <div className="flex items-center mb-2">
                      <span className='wght-semibold'>BirthDate:&nbsp;</span><span>{data.infoBirthDate}</span>
                    </div>
                    {data.infoIdCard &&
                      <div className="flex items-center mb-2">
                        <span className='wght-semibold'>ID Card:&nbsp;</span><span>{data.infoIdCard}</span>
                      </div>
                    }
                    <div className="flex items-center mb-2">
                      <span className='wght-semibold'>Email:&nbsp;</span><span>{data.userEmail}</span>
                    </div>
                    <div className="flex items-center">
                      <span className='wght-semibold'>Point of sale:&nbsp;</span><span className='px-2 ml-2 text-white rounded bg-purpleDark'>{data.pointOfSale}</span>
                    </div>
                  </div>
                  <div className='mx-4'>
                    {data.ticketTypeTitle &&
                      <div className='mr-2 '>
                        <span className='wght-semibold'>Ticket type:&nbsp;</span><span>{data.ticketTypeTitle}</span>
                      </div>
                    }
                    {data.ticketTypeImage && appConfig && appConfig.media && !!data.ticketTypeImage?.length &&
                      <div className='mr-2 '>
                        <img className='h-32 rounded' src={`${appConfig.media.public}${appConfig.media.resized}${data.ticketTypeImage}`} />
                      </div>
                    }
                  </div>
                </div>
              </div>
              <div className='p-2 mt-4 bg-white rounded'>
                <span className='inline-block w-full'>
                  <p className='wght-semibold'>Manager comment</p>
                  <textarea
                    readOnly={role === 'read'}
                    name="managerComment"
                    className={`w-full px-2 py-1 rounded bg-grayLight outline-none resize-y`}
                    placeholder={'Comment'}
                    value={managerComment || ''}
                    onChange={e => setManagerComment(e.target.value)}
                  />
                </span>
                <span className='inline-block w-1/2'>
                  <p className='wght-semibold'>Wristband Code</p>
                  <span className='flex'>
                    <input
                      readOnly={role === 'read'}
                      name="wristbandCode"
                      ref={inputWristbandcode}
                      onFocus={(event) => event.target.select()}
                      className={`w-full px-2 py-1 rounded bg-grayLight outline-none resize-y ${wristBandCodeError || wristBandCodeNoAlphanumeriError && 'border border-error'}`}
                      placeholder={'Comment'}
                      value={wristbandCode || ''}
                      onChange={e => setWristbandCode(e.target.value.toUpperCase())}
                    />
                    <Button
                      visibility={role === 'admin'}
                      size="small"
                      onClick={generateRandomWristbandCode}
                      color='white'
                      className='ml-2 whitespace-nowrap '
                    >
                      Random code
                    </Button>
                  </span>
                  {/* <p className='text-sm'>Prefix</p>
                  <span className='flex'>
                    <input
                      readOnly={role === 'read'}
                      name="wristbandCodePrefix"
                      className={`w-full px-2 py-1 rounded bg-grayLight outline-none resize-y ${wristBandCodeError || wristBandCodeNoAlphanumeriError && 'border border-error'}`}
                      placeholder={'Prefix'}
                      value={prefix || ''}
                      onChange={e => handlePrefix(e.target.value.toUpperCase())}
                    />
                  </span> */}
                  {wristBandCodeError &&
                    <p className="text-error">Wristband code already exists</p>
                  }
                  {wristBandCodeNoAlphanumeriError &&
                    <p className="text-error">Wristband code has no alphanumeric characters</p>
                  }
                </span>
                {
                  role !== 'read' && <div className='flex justify-end'>
                    <Button className='mr-2' onClick={updateData}>Save</Button>
                    <Button color='error' onClick={resetData}>Close</Button>
                  </div>
                }
              </div>
              {lastEditInfo && <div className='p-2 mt-4 text-white rounded bg-warning'>
                <p className='wght-bold'>This ticket has alredy a Wristband Code</p>
                <p>Date:&nbsp;&nbsp;{dayjs(lastEditInfo.date).format('DD/MM/YYYY')}</p>
                <p>Time:&nbsp;&nbsp;{dayjs(lastEditInfo.date).format('HH:mm:ss')}</p>
                <p>By:&nbsp;&nbsp;{lastEditInfo.user}</p>
              </div>}
            </>
          }
        </>
      }
    </>
  )
}

export default Wristband