import React, { useState, useEffect, useRef } from 'react'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'
import { useSession } from '../contexts/AuthContext'

const ModalPhoneRecovery = ({ params, show, showState, readMode, event }) => {
  const [loading, setLoading] = useState(false)
  const [recoveryCode, setRecoveryCode] = useState()
  const token = useSession()
  const { getEvent, getOrganization } = useOrganizationInfo(event)

  const generateCode = () => {
    if (readMode) return
    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}phone-recovery`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName: 'phoneRecovery',
        organization: getOrganization(),
        event: getEvent(),
        generateCode: true,
        generateCodeForId: params.selectedNumber._id,
      }),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        setLoading(false)
        if (response.status === 200 && response.body.code) {
          setRecoveryCode(response.body.code)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  const closeModal = () => {
    setRecoveryCode()
    showState(false)
  }

  return <>
    {show &&
      <div className="fixed inset-0 z-40 flex items-center justify-center p-4 pt-0" style={{ backgroundColor: 'rgba(255,255,255,.5)' }}>
        <div className='absolute inset-0' onClick={closeModal}></div>
        <div className="relative z-50 flex flex-col justify-center p-0 bg-white border rounded md:w-1/2 border-gray">

          <div className='flex items-center justify-end'>
            <div onClick={closeModal} className='p-2 cursor-pointer'>×</div>
          </div>
          <div className='px-4 pb-4'>
            <div className='flex flex-col justify-between'>
              <p>Generate a new random code for this phone:</p>
              <p className='w-full my-2 text-center wght-semibold'>{params.selectedNumber.phoneNumber}</p>
              <p className='w-full mb-2 text-xs text-center'>{params.selectedNumber.deviceId}</p>

            </div>
            <div className='flex justify-center'>
              {loading ?
                <p className='m-auto'>generating...</p>
                :
                <>
                  {recoveryCode ?
                    <p className='m-auto'>{recoveryCode}</p>
                    :
                    <Button className='m-auto' onClick={generateCode}>Generate code</Button>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div>
    }
  </>
}

export default ModalPhoneRecovery
