import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSession } from '../contexts/AuthContext'
import { Modal } from "./Modal"
import dayjs from 'dayjs'
import Cookies from 'js-cookie'
import Button from './Button'


const GuestsInvitationsExport = ({
  showModal,
  setShowModal,
  exportFieldsSelected,
  setExportFieldsSelected,
  loading,
  setLoading,
  modelName,
  filters,
  page,
  getParsedPathname,
}) => {
  const history = useHistory()
  const token = useSession()

  const exportInvitations = () => {
    const formData = {}
    formData.exportFieldsSelected = exportFieldsSelected
    formData.modelName = modelName
    formData.filters = filters
    formData.url = getParsedPathname(page)
    formData.organization = Cookies.get('organization')
    formData.event = filters.GuestsEvent

    setLoading(true)
    fetch(`${process.env.REACT_APP_API_URL}export-guests-invitation`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(formData),
    }).then(response => response
      .json()
      .then(data => ({ status: response.status, body: data })))
    .then(response => {
      setLoading(false)
      if (response.status === 200) {
        const csv = response.body.csv
        const blob = new Blob([csv], { type: 'text/csv' })
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')

        link.setAttribute('href', url)
        const dateTime = dayjs().format('YYYY-MM-DD_HH-mm-ss')
        link.setAttribute('download', `${dateTime} Guests Invitations.csv`)
        link.style.visibility = 'hidden'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      } else {
        console.log('Error', response.status, response.body.error)
        if (response.status === 403) {
          Cookies.remove('user')
          history.push('/login')
        } else if (response.status === 401) {
          history.push(`/admin/no-access?url=${window.location.pathname}`)
        } else if (response.body.error === 'no-invitations') {
          alert('No invitations found for this event')
        } else {
          alert('Error exporting invitations')
        }
      }
    }).catch(err => {
      setLoading(false)
      console.log('Error', err)
    })
  }

  const ExportField = ({ field }) => <span className="flex w-1/3 mr-4">
    <input type="checkbox" id={field} className='cursor-pointer exportFields' onChange={
      (e) => {
        document.getElementById('allFields').checked = false;
        const rawExportFieldsSelected = [...exportFieldsSelected];
        if (e.target.checked) {
          rawExportFieldsSelected.push(e.target.id)
        } else {
          rawExportFieldsSelected.splice(rawExportFieldsSelected.indexOf(e.target.id), 1)
        }
        setExportFieldsSelected(rawExportFieldsSelected)
      }}
      checked={exportFieldsSelected.indexOf(field) === -1 ? false : true }
    />
    <label className="ml-1 text-sm cursor-pointer" for={field} >{field}</label>
  </span>

  if (!showModal) return null

  return (
    <Modal setShowModal={setShowModal} showModal={showModal}>
      <div className="relative w-2/3 pb-0 overflow-auto bg-white z-1 max-h-128">
        <div className="flex items-center justify-between w-full p-8 pb-2 h-fit">
          <h2 className="text-xl wght-semibold">Export invitations</h2>
        </div>

        <div className="w-full p-8 pt-2 pb-0">
          <h3 className="mb-1 wght-semibold">Fields to export:</h3>
        </div>
        <div className="p-8 pt-0 pb-0 mb-1 select-none">
          <input
            type="checkbox"
            id="allFields"
            className="cursor-pointer"
            onChange={(e) => {
              const checkboxes = document.querySelectorAll('.exportFields')

              const rawSelected = []
              checkboxes.forEach((checkbox) => {
                checkbox.checked = e.target.checked
                rawSelected.push(checkbox.id)
              })

              if (!e.target.checked) {
                setExportFieldsSelected([])
              } else {
                setExportFieldsSelected(rawSelected)
              }
            }}
          />
          <label
            className="ml-1 text-sm cursor-pointer wght-semibold"
            for="allFields"
          >
            All fields
          </label>
        </div>
        <div className="flex flex-wrap w-full p-8 pt-0 select-none">
          <span>
            <span className="text-sm wght-semibold">Invitation:</span>
            {[
              '_id',
              'name',
              'lastname',
              'email',
              'ticketId',
              'publicId',
              'ticketType',
              'event',
            ].map((field, index) => {
              return <ExportField key={`${field}-${index}`} field={field} />
            })}
          </span>
          <span>
            <span className="text-sm wght-semibold">Guests:</span>
            {[
              'guestsquota',
              'guestsentity',
              'guestscategory',
              'defaultlanguage',
              'sendmail',
              'mailstatus',
            ].map((field, index) => {
              return <ExportField key={`${field}-${index}`} field={field} />
            })}
          </span>
          <span>
            <span className="text-sm wght-semibold">Manager:</span>
            {['updateDate', 'updateUser', 'creationDate', 'creationUser'].map(
              (field, index) => {
                return <ExportField key={`${field}-${index}`} field={field} />
              }
            )}
          </span>
        </div>

        <div className="sticky bottom-0 left-0 flex items-end justify-end p-8 text-xs bg-white border-grayLight">
          {loading ? (
            <div>
              <span className="px-2 py-1 text-xs border rounded cursor-pointer text-primary bg-primaryLighter border-primary">
                Downloading...
              </span>
            </div>
          ) : (
            <div className='flex'>
              <Button className='mr-2 text-base' onClick={() => setShowModal(false)} color="error">Cancel</Button>
              <Button className='mr-2 text-base' onClick={() => exportInvitations(false)}>Export</Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default GuestsInvitationsExport