import React, { useEffect, useState } from 'react'
import defaultValue from '../Lib/defaultValue'
import Button from '../Button'
import dayjs from 'dayjs'
import { useSession } from '../../contexts/AuthContext'

import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
dayjs.extend(utc)
dayjs.extend(timezone)

const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}

const Rules = ({
  storedData,
  onChange: updateStored,
  name,
  translatable,
  options: { label },
  readMode,
  timezone,
}) => {
  const [updateOnlyTicketType, setUpdateOnlyTicketType] = useState(true)
  const [ticketTypes, setTicketTypes] = useState()
  const [selectedTickets, setSelectedTickets] = useState()
  const [value, setValue] = useState(
    Object.values(defaultValue(name, storedData, translatable, [])).map(
      m => {
        if (typeof m.key === 'undefined') m.key = generateToken(30)
        return m
      },
    ),
  )
  const token = useSession()


  const getTicketType = (type) => {

    const bodyData = {}
    if (storedData && storedData.eventLocation) {
      bodyData.eventLocation = storedData.eventLocation
    }
    if (type === 'firstRender' && value) {
      bodyData.tickets = value.map(v => ({ key: v.key, tickets: v.tickets }))
    }

    fetch(`${process.env.REACT_APP_API_URL}zone-rules`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          if (response.body.tickets) {
            const rawValue = [...value]
            const rawData = (selectedTickets) ? [...selectedTickets] : []
            rawValue.map(v => {
              if (v.tickets) {
                v.tickets.map((ticket) => {
                  response.body.tickets.map(responseticket => {
                    if (responseticket._id === ticket) {
                      rawData.push({ _id: responseticket._id, title: `${responseticket.fields.firstName} ${responseticket.fields.lastName}` })
                    }
                  })
                })
              }
              return v
            })
            setSelectedTickets(rawData)
          }
          let rawTicketTypes = response.body.items
          if (rawTicketTypes.length === undefined) {
            rawTicketTypes = [rawTicketTypes]
          }
          setTicketTypes(rawTicketTypes)
          setUpdateOnlyTicketType(false)
          if (type === 'addModel') {
            addModel(rawTicketTypes)
          }
        } else {
          if (response.status === 202 && response.body.error === 'NO_TICKET_TYPE') {
            setTicketTypes()
            if (type === 'addModel') {
              addModel(false)
            }
          }
        }
      })
  }


  const onChange = (event, key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        if (event.target.name === 'startDate' || event.target.name === 'endDate') {
          item[event.target.name] = dayjs.tz(event.target.value, timezone).utc().toISOString()
        } else {
          item[event.target.name] = event.target.value
        }
      }
      return item
    })
    setValue(rawValue)
  }

  const shouldAddMultipleValue = (event, key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        const v = event.target.value
        let val = item[event.target.name]
        if (!val || !val.length) val = []

        if (val.length === 0 || (val.length > 0 && !val.some(item => item === v))) {
          val.push(v)
        }

        item[event.target.name] = val
      }
      return item
    })
    setValue(rawValue)

    if (event.target.type === 'ticket') {
      const rawData = (selectedTickets) ? [...selectedTickets] : []
      rawData.push({ _id: event.target.value, title: event.target.title })
      setSelectedTickets(rawData)
    }
  }

  useEffect(() => {
    updateStored(name, value)
  }, [value])

  const ListItem = ({ item }) => (
    <div className="w-full p-4 my-2 rounded bg-grayLight" key={item.key}>
      <div className="flex w-full mb-4">
        <div className="flex flex-col pr-4">
          <p className="pr-4">Type</p>
          <select
            name="type"
            type="text"
            placeholder="Type"
            value={item.type}
            onChange={(event) => onChange(event, item.key)}
            className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray`}
            disabled={readMode}
          >
            <option value="include">Include</option>
            <option value="exclude">Exclude</option>
          </select>
        </div>
        <div className="flex flex-col pr-4">
          <p className="pr-4">Start Date</p>
          <input
            name="startDate"
            className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
            type="datetime-local"
            placeholder={label}
            value={dayjs.tz(item.startDate, 'UTC').tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(event) => onChange(event, item.key)}
            readOnly={readMode}
          />
        </div>
        <div className="flex flex-col pr-4">
          <p className="pr-4">End Date</p>
          <input
            name="endDate"
            className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
            type="datetime-local"
            placeholder={label}
            value={dayjs.tz(item.endDate, 'UTC').tz(timezone).format('YYYY-MM-DDTHH:mm:ss')}
            onChange={(event) => onChange(event, item.key)}
            readOnly={readMode}
          />
        </div>
        <span className="pl-1 ml-auto cursor-pointer" onClick={() => removeRule(item.key)}>
          ✕
        </span>
      </div>
      {ticketTypes &&
        <div className="flex w-full">
          <div className="flex flex-col w-1/2 pr-4">
            <p className="pr-4">Ticket Type</p>
            <select
              name="ticketType"
              type="text"
              placeholder="TicketType"
              value={-1}
              onChange={(event) => shouldAddMultipleValue(event, item.key)}
              className={`w-full rounded px-2 py-1 outline-none placeholder-gray`}
              disabled={readMode}
            >
              <option value="-1">Select</option>
              {ticketTypes && Array.isArray(ticketTypes) && ticketTypes.map((ticketType, index) =>
                <option
                  key={index}
                  value={ticketType._id}
                  disabled={item.ticketType && Array.isArray(item.ticketType) && item.ticketType.includes(ticketType._id)}
                >
                  {ticketType.title}
                </option>
              )}
              {ticketTypes && !Array.isArray(ticketTypes) && <option value={ticketTypes._id}>{ticketTypes.title}</option>}
            </select>
            {ticketTypes && item.ticketType.length > 0 && ticketTypes.length > 0 && (
              <div className="flex flex-wrap mt-2">
                {item.ticketType
                  .map(id => id.length > 0 && multipleOption(ticketTypes.find(item => item._id === id), item.key, 'ticketType'))}
              </div>
            )}
          </div>
        </div>
      }
    </div>
  )

  const removeRule = (key) => {
    if (readMode) return
    const rawValue = [...value]
    setValue(rawValue.filter(item => item.key !== key))
  }

  const multipleOption = (option, key, type) => {
    if (option) {
      return (
        <div
          key={option._id}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${option._id}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(option._id, key, type)}>
            ✕
          </span>
        </div>
      )
    }
  }

  const removeItem = (id, key, type) => {
    if (readMode) return
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        item[type] = item[type].filter(item => item !== id)
      }
      return item
    })
    setValue(rawValue)
  }

  const List = ({ items }) => {
    const renderItems = []
    items.map((item, index) => {
      return renderItems.push(ListItem({ key: index, item: item }))
    })
    return renderItems
  }

  const addAction = async () => {
    if (readMode) return
    if (storedData.eventLocation && storedData.eventLocation !== -1 && !ticketTypes) {
      getTicketType('addModel')
    } else {
      addModel()
    }
  }

  useEffect(() => {
    if (updateOnlyTicketType && storedData.eventLocation && storedData.eventLocation !== -1) {
      getTicketType('firstRender')
    }
  }, [])

  useEffect(() => {
    if (!updateOnlyTicketType) {
      addModel()
    }
  }, [ticketTypes])

  const addModel = async (ticketTypesFallback) => {
    const ticketTypesArray = ticketTypes || ticketTypesFallback
    if (ticketTypesArray) {
      let event = false
      if (Array.isArray(ticketTypesArray)) {
        event = ticketTypesArray[0].event[0]
      } else {
        event = ticketTypesArray.event[0]
      }
      const eventStartDate = event.startDate
      const eventEndDate = event.endDate

      setValue([
        ...value,
        {
          key: generateToken(30),
          type: 'include',
          startDate: eventStartDate,
          endDate: eventEndDate,
          tickets: event.tickets,
          ticketType: false,
        },
      ])
    } else {
      setValue([
        ...value,
        {
          key: generateToken(30),
          type: 'include',
          startDate: dayjs().format('YYYY-MM-DDTHH:mm'),
          endDate: dayjs().format('YYYY-MM-DDTHH:mm'),
          tickets: false,
          ticketType: false,
        },
      ])
    }
  }

  if (storedData && storedData.eventLocation && storedData.eventLocation !== -1) {
    return (
      <div>
        <div className="flex flex-row items-end justify-between">
          <label>{label}</label>
          <Button onClick={() => addAction()} color="primary" size="small" className='mx-0'>Add rule</Button>
        </div>
        { value.length ?
          List({ items: value }) :
          <div className="w-full px-4 py-2 my-2 text-xs rounded bg-grayLight">
            No rules
          </div>
        }
      </div>
    )
  } else {
    return false
  }

}

export default Rules
