import useLocalStorage from './useLocalStorage'
import Cookies from 'js-cookie'

function useOrganizationInfo(event) {
  const [ organizationEvents ] = useLocalStorage("organizationEvents")

  const getEvent = (onlyObjectId) => {
    let eventId = false
    if (event !== 'all') {
      if (organizationEvents) {
        const organizationEvent = organizationEvents.find(e => e.slug === event)
        eventId = organizationEvent?.value || event
      } else if (!onlyObjectId) {
        eventId = event
      }
    }

    return eventId
  }

  const getOrganization = () => {
    return Cookies.get('organization')
  }

  return { getOrganization, getEvent }
}

export default useOrganizationInfo