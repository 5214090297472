import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import Cookies from 'js-cookie'
import Select from './Select'
import { useSession } from '../../contexts/AuthContext'

const SelectDouble = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: {
    type, label, secondLabel, model, secondModel, multiple, options: staticOptions,
  },
  validations,
  languages,
  find,
  innerForm,
  readMode,
}) => {

  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [options, setOptions] = useState([{ _id: -1, title: label }])
  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable, multiple ? [] : -1),
  )
  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(multiple ? [] : -1)
  const token = useSession()


  const shouldAddMultipleValue = (val, v) => {
    if (!val.includes(v)) {
      val.push(v)
    }
    return [...val]
  }

  const onChangeText = e => {
    const storeObject = {}
    storeObject[model] = firstValue
    storeObject[secondModel] = e.target.value
    setValue(storeObject)
  }

  const removeItem = id => {
    if (readMode) return
    if (translatable) {
      value[language] = value[language].filter(item => item !== id)
      setValue({ ...value })
    } else {
      setValue([...value.filter(item => item !== id)])
    }
  }

  useEffect(() => {
    if (value && value[[secondModel]]) {
      setCurrentValue(value[[secondModel]])
    }
  }, [value, language, translatable])

  useEffect(() => {
    if (type === 'static') {
      setOptions(o => [
        ...o,
        ...Object.keys(staticOptions).map(key => ({
          _id: key,
          title: staticOptions[key],
        })),
      ])
    } else {
      setOptions([{ _id: -1, title: 'Loading...' }])
      if (typeof find[model] !== 'undefined') {
        if (model === 'Event' && (value === -1 || value.length === 0)) {
          const generalEventSelected = find[model].find(event => event._id === Cookies.get('event'))
          if (generalEventSelected) {
            const newValue = multiple ? [generalEventSelected._id] : generalEventSelected._id
            setValue(newValue)
          }
        }
      }
    }
  }, [name, type, staticOptions])

  useEffect(() => {
    const errs = Errors(value[secondModel], validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  const multipleOption = id => {
    const option = options.find(item => item._id === id)
    if (model === 'Event' && !option) {
      return false
    } else {
      return (
        <div
          key={id}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${id}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(id)}>
            ✕
          </span>
        </div>
      )
    }
  }
  const select = (
    <div>
      <div className="relative">
        <div className="absolute right-0 py-1 mr-2">&#8595;</div>
        <select
          name={name}
          className={`w-full px-2 py-1 ${innerForm ? 'bg-white' : 'bg-grayLight'} ${showErrors && errors.length > 0 ? 'border border-error' : ''
            } rounded ${translatable && Object.keys(languages)[0] === language
              ? 'rounded-tl-none'
              : ''
            } outline-none appearance-none`}
          type="text"
          placeholder={label}
          value={currentValue}
          onChange={onChangeText}
          disabled={readMode}
        >
          {options.map(option => (
            <option
              key={option._id}
              disabled={
                option.disabled
                || (multiple && currentValue.includes(option._id))
              }
              value={option._id}
            >
              {option.title}
            </option>
          ))}
        </select>
      </div>
      {multiple && currentValue.length > 0 && options.length > 1 && (
        <div className="flex flex-wrap mt-2">
          {currentValue
            .map(id => id.length > 0 && multipleOption(id))}
        </div>
      )}
    </div>
  )

  const onChangeFirstSelect = (storedValue, newValue) => {
    if (newValue != -1) {
      setFirstValue(newValue)
    } else {
      setFirstValue()
    }
  }

  const [firstValue, setFirstValue] = useState()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (firstValue && !loading) {
      setLoading(true)
      fetch(`${process.env.REACT_APP_API_URL}select-double`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          modelName: secondModel,
          relatedId: firstValue
        }),
      })
        .then(response => response.json().then(data => ({ status: response.status, body: data })))
        .then(response => {
          setLoading(false)
          if (response.status === 200) {
            const options = response.body.items.map(item => {
              const title = typeof item.title === 'string' ? item.title : item.title['en']
              return {
                _id: item._id,
                title,
              }
            })
            setOptions([{ _id: -1, title: secondLabel }, ...options])
          } else {
            setOptions([{ _id: -1, title: `No ${secondModel} of ${model} ${firstValue}` }])
          }
        })
    }
  }, [firstValue])

  const [storedDataFirstSelect, setStoredDataFirstSelect] = useState()
  useEffect(() => {
    const rawStoredDataFirstSelect = { ...storedData }
    rawStoredDataFirstSelect[model] = storedData[name][model]
    setStoredDataFirstSelect(rawStoredDataFirstSelect)
  }, [])

  return (
    <div className="flex flex-row">
      <div className="w-1/2 pr-2">
        {storedDataFirstSelect &&
          <Select
            storedData={storedDataFirstSelect}
            onChange={onChangeFirstSelect}
            showErrors={showErrors}
            name={model}
            translatable={translatable}
            options={{
              type,
              label,
              model,
              multiple,
              options,
            }}
            validations={validations}
            languages={languages}
            find={find}
            innerForm={innerForm}
          ></Select>
        }
      </div>
      {firstValue && <div className="w-1/2 pl-2">
        <label>{secondLabel}</label>
        {select}
        {showErrors && errors.length > 0 &&
          <p className="text-error">{errors[0]}</p>
        }
      </div>}
    </div>
  )
}

export default SelectDouble
