import React, { useState, useEffect } from 'react'
import { useParams, useHistory, NavLink } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import Cookies from 'js-cookie'
import dayjs from 'dayjs'
import { ListControlDeviceHistory } from '../../externalApi/gql.services'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import StatsProgressBar from '../StatsProgressBar'
import Pagination from '../Pagination'
import Button from '../Button'

const ControlDeviceDetail = () => {
  const token = useSession()
  const { deviceId, event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const [data, setData] = useState()
  const [deviceInfo, setDeviceInfo] = useState()
  const [deviceLogs, setDeviceLogs] = useState()
  const [loading, setLoading] = useState(false)
  const [loadingDevice, setLoadingDevice] = useState(false)
  const [loadingLogs, setLoadingLogs] = useState(false)
  const [types, setTypes] = useState([])
  const [page, setPage] = useState(0)
  const [hasMorePages, setHasMorePages] = useState()
  const [pageLogs, setPageLogs] = useState(0)
  const [hasMorePagesLogs, setHasMorePagesLogs] = useState()
  const pageLimitLogs = 50
  const pageLimit = 50
  const cookiesDisplayedTypes = Cookies.get('control-devices-types') ? Cookies.get('control-devices-types').split(',') : []
  const [displayedTypes, setDisplayedTypes] = useState(cookiesDisplayedTypes)
  const history = useHistory()

  const fetchData = async () => {
    setLoading(true)
    const res = await fetch(process.env.REACT_APP_GRAPH_URL, {
      method: 'POST',
      headers: {
        Authorization: `${token}`,
        'AccessTicket-App': 'manager',
      },
      body: JSON.stringify({
        query: ListControlDeviceHistory,
        variables: {
          deviceId,
          limit: pageLimit,
          skip: pageLimit * page,
        },
      }),
    })
    const {data, errors: responseErrors} = await res.json()
    setLoading(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      if (data?.listControlDeviceHistory?.history?.length === pageLimit) {
        setHasMorePages(true)
      }
      setData(data?.listControlDeviceHistory)
      const types = [...new Set(data?.listControlDeviceHistory?.history.map(h => h.type))]
      setTypes(types)
    }
  }

  const fetchDeviceData = async () => {
    setLoadingDevice(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}control-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        action: 'getDeviceInfo',
        deviceId,
      }),
    })

    const { device, errors: responseErrors} = await res.json()
    setLoadingDevice(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      if (device) {
        setDeviceInfo(device)
      }
    }
  }

  const fetchDevicelogs = async () => {
    setLoadingLogs(true)
    const res = await fetch(`${process.env.REACT_APP_API_URL}control-list`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        organization: getOrganization(),
        event: getEvent(),
        action: 'getDevicelogs',
        deviceId,
        limit: pageLimitLogs,
        skip: pageLimitLogs * pageLogs,
      }),
    })

    const { logs, errors: responseErrors} = await res.json()
    setLoadingLogs(false)
    if (responseErrors) {
      responseErrors.forEach(error => console.log('Error', error))
    } else {
      if (logs) {
        if (logs.length === pageLimitLogs) {
          setHasMorePagesLogs(true)
        }
        setDeviceLogs(logs)
      }
    }
  }

  useEffect(() => {
    fetchData()
    fetchDeviceData()
    fetchDevicelogs()
  }, [])

  useEffect(() => {
    if (!loading) {
      fetchData()
    }
  }, [page])

  useEffect(() => {
    if (!loading) {
      fetchDevicelogs()
    }
  }, [pageLogs])

  useEffect(() => {
    if (displayedTypes) {
      Cookies.set('control-devices-types', displayedTypes.join(','))
    }
  }, [displayedTypes])

  const deviceDataFields = [
    "name",
    "deviceId",
    "event",
    "zone",
    "ticketId",
    "publicId",
    "category",
    "updateDate",
    "hexId",
    "nfcMode",
    "offlineCapable",
    "type",
  ]

  return (
    <div>
      <Helmet>
        <title>Control Device</title>
      </Helmet>
      <div className="text-xs">
        { loadingDevice && <div className="flex mt-8 text-base">
          <p>Loading...</p>
          </div>
        }

        {deviceInfo &&
          <div className='mb-8'>
            <div className='flex items-start justify-between'>
              <h2 className="mb-4 text-xl wght-bold">{deviceInfo.name}</h2>
              <div className="mb-2 h-fit">
                <Button size="small" color="white" to={`${history.location.pathname.split('controldevices')[0]}list/ControlDevices`} >Back to list</Button>
              </div>
            </div>
            <div className="flex flex-wrap mb-4 text-sm md:flex-nowrap">
              <table className='w-full h-full pr-4 mr-2 lg:w-full'>
                <tbody className='pr-2 bg-white'>
                  {deviceDataFields.map((field, index) => (index < ((deviceDataFields.length) / 2) ) && <tr>
                    <td className="w-32 p-1 border border-grayLight">{field}</td>
                    <td className="p-1 border border-grayLight">
                      {['updateDate'].includes(field) && dayjs(deviceInfo[field]).format('DD/MM/YYYY HH:mm:ss')}
                      {['nfcMode'].includes(field) && !deviceInfo[field] && 'OFF'}
                      {['nfcMode'].includes(field) && deviceInfo[field] && 'ON'}
                      {['offlineCapable'].includes(field) && !deviceInfo[field] && 'YES'}
                      {['offlineCapable'].includes(field) && deviceInfo[field] && 'NO'}
                      {['ticketId'].includes(field) && deviceInfo[field] && <Link
                        to={`/admin/all/edit/Ticket/${deviceInfo['ticket_Id']}`}
                        className="text-primary"
                      >{deviceInfo[field]}</Link>
                      }
                      {['publicId'].includes(field) && deviceInfo[field] && <Link
                        to={`/admin/all/edit/Ticket/${deviceInfo['ticket_Id']}`}
                        className="text-primary"
                      >{deviceInfo[field]}</Link>
                      }

                      {!['updateDate','offlineCapable','nfcMode','ticketId','publicId',].includes(field) && deviceInfo[field]}
                    </td>
                  </tr>)}
                </tbody>
              </table>
              <table className='w-full h-full pr-4 mr-2 lg:w-full'>
                <tbody className='pr-2 bg-white'>
                  {deviceDataFields.map((field, index) => (index > ((deviceDataFields.length - 1) / 2) ) && <tr>
                    <td className="w-32 p-1 border border-grayLight">{field}</td>
                    <td className="p-1 border border-grayLight">
                      {['updateDate'].includes(field) && dayjs(deviceInfo[field]).format('DD/MM/YYYY HH:mm:ss')}
                      {['nfcMode'].includes(field) && !deviceInfo[field] && 'OFF'}
                      {['nfcMode'].includes(field) && deviceInfo[field] && 'ON'}
                      {['offlineCapable'].includes(field) && !deviceInfo[field] && 'YES'}
                      {['offlineCapable'].includes(field) && deviceInfo[field] && 'NO'}
                      {['ticketId'].includes(field) && deviceInfo[field] && <Link
                        to={`/admin/all/edit/Ticket/${deviceInfo['ticket_Id']}`}
                        className="text-primary"
                      >{deviceInfo[field]}</Link>
                      }
                      {['publicId'].includes(field) && deviceInfo[field] && <Link
                        to={`/admin/all/edit/Ticket/${deviceInfo['ticket_Id']}`}
                        className="text-primary"
                      >{deviceInfo[field]}</Link>
                      }

                      {!['updateDate','offlineCapable','nfcMode','ticketId','publicId',].includes(field) && typeof deviceInfo[field] === 'string' && deviceInfo[field] }
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </div>

            <StatsProgressBar
              index={0}
              title="Zone Capacity"
              total={deviceInfo.capacity || "0"}
              current={deviceInfo.occupancy || "0"}
            />
          </div>
        }
        
        {deviceLogs && <div className='mb-8 text-sm'>
          <h3 className="mb-2 text-lg wght-semibold ">Device logs</h3>
          {deviceLogs.length === 0 && <span>No logs</span>}
          { !!deviceLogs.length && <div>
            <table className='w-full h-full pr-4 mr-2 text-center'>
              <thead className='pr-2 bg-white'>
                <tr>
                  <th className="p-2 border border-grayLight">Type</th>
                  <th className="p-2 border border-grayLight">Date</th>
                  <th className="p-2 border border-grayLight">Time</th>
                  <th className="p-2 border border-grayLight">Zone</th>
                  <th className="p-2 border border-grayLight">Ticket</th>
                </tr>
              </thead>
              <tbody className='pr-2 bg-white'>
                {deviceLogs.map(log => <tr>
                  <td className={`text-center text-success border border-grayLight text-xl px-4 whitespace-nowrap ${log?.type === 1 ? '' : 'text-error'}`}>{log?.type === 1 ? '→' : '←'}</td>
                  <td className="p-2 border border-grayLight">{dayjs(log?.date).format('DD/MM/YYYY')}</td>
                  <td className="p-2 border border-grayLight">{dayjs(log?.date).format('HH:mm:ss')}</td>
                  <td className="p-2 border border-grayLight">{log?.zone?.title}</td>
                  <td className="p-2 border border-grayLight">
                    <Link to={`/admin/all/edit/Ticket/${log?.ticketId}`} className="text-primary">{log?.ticketId}</Link>
                  </td>
                </tr>)}
              </tbody>
            </table>
            {
              loadingLogs && <div className="flex justify-end mt-8">
                <p>Loading...</p>
              </div>
            }
            <Pagination
              page={pageLogs}
              setPage={setPageLogs}
              hasMorePages={hasMorePagesLogs}
              loading={loadingLogs}
            />
          </div>}
          </div>
        }

        {!loadingDevice && data && <>
          <h3 className="mb-4 text-lg wght-semibold ">Device History</h3>
          {types.length > 0 && <h4 className="mb-2 text-base wght-semibold ">Log type:</h4>}
          <div className="mb-4 text-xs">
            {types.map(type => <span onClick={() => {
              if (displayedTypes.includes(type)) {
                setDisplayedTypes([...displayedTypes.filter(t => t !== type)])
              } else {
                displayedTypes.push(type)
                setDisplayedTypes([...displayedTypes])
              }
            }} className={`inline-block p-1 mb-1 mr-1 rounded cursor-pointer ${displayedTypes.includes(type) ? 'bg-primary text-white' : 'bg-white'}`}>{type}</span>)}
          </div>
          {data.history.filter(h => {
            return displayedTypes.length === 0 || displayedTypes.includes(h.type)
          }).map((history, index) => {
            if (!history) return
            return <div key={index} className={`mb-4 rounded ${history?.type === 'rebooted' ? 'bg-yellow' : 'bg-white'}`}>
              <div className="flex justify-between p-1 border-b border-gray">
                <span className="wght-bold">{history.type}{history.userName && ` (by ${history.userName})`}</span>
                <span>{history.date && dayjs.unix(history.date/1000).format('DD/MM/YYYY HH:mm:ss')}</span>
              </div>
              {history.data && Object.keys(history.data).length > 0 && <div className="p-1">
                {Object.keys(history.data).map(key => {
                  if (['deviceId', 'name'].includes(key)) return
                  return (
                    <span key={key} className="block">
                      <span className="wght-semibold">{key}: </span>
                      <span>{typeof history.data[key] === 'boolean' ? (history.data[key] ? 'yes' : 'no') : history.data[key]}</span>
                    </span>
                  )
                })}
              </div>}
            </div>
          })}

          <Pagination
            page={page}
            setPage={setPage}
            hasMorePages={hasMorePages}
            loading={loading}
          />
        </>}
      </div>
    </div>
  )
}

export default ControlDeviceDetail