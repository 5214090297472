import React, { useState, useEffect } from 'react'
import { useParams, useHistory, NavLink, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import useLocalStorage from '../hooks/useLocalStorage'
import dayjs from 'dayjs'
import { useSession } from '../contexts/AuthContext'
import useOrganizationInfo from '../hooks/useOrganizationInfo'
import Button from './Button'
import { getUserPreference, updateCookiePreferences } from './Lib/utils'
import Loader from "./Loader"

const StatsEventZone = () => {
  const { id, page: pageParam, type: typeParam, event } = useParams()
  const { getOrganization, getEvent } = useOrganizationInfo(event)
  const modelName = 'stats'
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  const [title, setTitle] = useState(`Stats zones`)
  const [page, setPage] = useState(pageParam - 1 || 0)
  const [hasMorePages, setHasMorePages] = useState(false)
  const pageLimit = 500
  const [typeEphemerals, setTypeEphemerals] = useState(false)
  const [selectedZone, setSelectedZone] = useState(id)
  const [eventZones, setEventZones] = useState()
  const [userSortSettings, setUserSortSettings] = useLocalStorage(
    'user_sort_settings',
    {}
  )
  const [sortedValue, setSortedValue] = useState('date')
  const [sortAscending, setSortAscending] = useState(false)
  const [sortedItems, setSortedItems] = useState()
  const [statsType, setStatsType] = useState(typeParam || 'live')
  const [ticketTypesByZone, setTicketTypesByZone] = useState()

  const [capacity, setCapacity] = useState()
  const [occupancy, setOccupancy] = useState()
  const [graphMarks, setGraphMarks] = useState([])
  const [total, setTotal] = useState(0)
  const [role, setRole] = useState(false)
  const [autoRefresh, setAutoRefresh] = useState(getUserPreference({
    parentKey: 'statsZones',
    key: 'autoRefresh',
    defaultValue: false,
  }))

  useEffect(() => {
    updateCookiePreferences({
      parentKey: 'statsZones',
      key: 'autoRefresh',
      value: autoRefresh,
    })
  }, [autoRefresh])

  const defaultUserManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
  const [sortBy, setSortBy] = useState(defaultUserManagerPreferences && defaultUserManagerPreferences.statsZonesSortBy || null)
  const token = useSession()


  useEffect(() => {
    if (autoRefresh) {
      const interval = setInterval(() => {
        setLoading(true)
      }, 10000)
      return () => clearInterval(interval)
    }
  }, [autoRefresh])


  useEffect(() => {
    if (!loading && typeof window !== 'undefined') {
      window.location.reload()
    }
  }, [id])



  useEffect(() => {
    let userManagerPreferences = Cookies.get('userManagerPreferences') && JSON.parse(Cookies.get('userManagerPreferences'))
    if (!userManagerPreferences) {
      userManagerPreferences = {}
    }

    userManagerPreferences.statsZonesSortBy = sortBy

    Cookies.set('userManagerPreferences', JSON.stringify(userManagerPreferences), { expires: 365 })
  }, [sortBy])

  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').format('YYYY-MM-DD')
  )
  const [endDate, setEndDate] = useState(dayjs().format('YYYY-MM-DD'))

  useEffect(() => {
    if (event !== 'all') {
      getData()
    }
  }, [])

  useEffect(() => {
    if (loading && event !== 'all') {
      getData()
    }
  }, [loading])

  useEffect(() => {
    setLoading(true)
  }, [sortBy])

  useEffect(() => {
    if (event === 'all') {
      getData()
    } else {
      setLoading(false)
    }
  }, [event])

  useEffect(() => {
    if (!loading && selectedZone) {
      setLoading(true)
    }
  }, [selectedZone, id])

  useEffect(() => {
    setHasMorePages()
    setLoading(true)

    let baseUrl = history.location.pathname
    if (selectedZone && history.location.pathname.indexOf(selectedZone) === -1) {
      if (history.location.pathname.split('/').pop() !== 'zones') {
        baseUrl = history.location.pathname.split('/')
        baseUrl.pop()
        baseUrl = baseUrl.join('/')
      }
      history.replace({ pathname: `${baseUrl}/${selectedZone}` })
    } else {
      history.replace({ pathname: `${baseUrl}` })
    }
  }, [statsType, selectedZone])

  useEffect(() => {
    if (statsType === 'live' && capacity) {
      updateGraph(parseInt(occupancy) || 0, parseInt(capacity) || 0)
    }
  }, [capacity, occupancy])

  const getData = () => {
    fetch(`${process.env.REACT_APP_API_URL}model-stats`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        modelName,
        id: selectedZone,
        statsType,
        startDate,
        endDate: dayjs(endDate).add(1, 'day').format('YYYY-MM-DD'),
        page,
        pageLimit,
        eventZones,
        sortBy,
        organization: getOrganization(),
        event: getEvent()
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoading(false)
        if (response.status === 200) {
          setRole(response.body.role)
          if (response.body.zones) {
            setEventZones(response.body.zones)
          }
          if (response.body.ticketTypesByZone) {
            setTicketTypesByZone(response.body.ticketTypesByZone)
          }
          if (response.body.eventLocation?.title) {
            setTitle(`Stats zone: ${response.body.eventLocation.title}`)
          }
          if (response.body.eventLocation?.accessType !== 'bar') {
            setTypeEphemerals(false)
          } else {
            setTypeEphemerals(true)
          }
          setData(response.body)

          response.body?.ticketTypesByZone?.length === 1 && setTotal(response.body.ticketTypesByZone[0].count)

          if (response.body && response.body.stats) {
            setHasMorePages(response.body.stats.length === pageLimit)
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.body.error === 'accestype_not_bar') {
            setStatsType('live')
          }
          setData({})
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          }
          if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
        }
      })
  }

  const updateGraph = (value, currentCapacity) => {
    const step = Math.round(currentCapacity / 6)
    const marks = []
    let newMark = step || 1
    while (newMark < currentCapacity) {
      marks.push(newMark)
      newMark += step || 1
    }
    setGraphMarks(marks)
  }

  useEffect(() => {
    const settings = { ...userSortSettings }
    if (
      typeof sortedValue === 'undefined' ||
      typeof sortAscending === 'undefined'
    ) {
      delete settings[modelName]
    } else {
      settings[modelName] = {
        value: sortedValue,
        ascending: sortAscending,
      }
    }
    setUserSortSettings(settings)
  }, [sortedValue, sortAscending])

  const handleSort = (key) => {
    if (sortedValue === key) {
      if (sortAscending) {
        setSortAscending(false)
      } else {
        setSortedValue(undefined)
        setSortAscending(undefined)
      }
    } else {
      setSortedValue(key)
      setSortAscending(true)
    }
  }

  useEffect(() => {
    if (
      typeof data === 'object' &&
      typeof data.stats !== 'undefined' &&
      data.stats
    ) {
      const stats = [...data.stats]
      if (typeof sortedValue !== 'undefined') {
        const sorted = stats.sort((a, b) => {
          const itemA =
            typeof a[sortedValue] == 'string'
              ? a[sortedValue].toLowerCase()
              : undefined
          const itemB =
            typeof b[sortedValue] == 'string'
              ? b[sortedValue].toLowerCase()
              : undefined

          if (typeof itemA === 'undefined' && typeof itemB === 'undefined')
            return 0
          if (typeof itemA === 'undefined') return sortAscending ? 1 : -1
          if (typeof itemB === 'undefined') return sortAscending ? -1 : 1

          if (itemA < itemB) return sortAscending ? -1 : 1
          if (itemA > itemB) return sortAscending ? 1 : -1
          return 0
        })
        setSortedItems(sorted)
      } else {
        setSortedItems(data.stats)
      }
    }
  }, [data, sortedValue, sortAscending, modelName])

  const onChangeDate = (e, type) => {
    const newDate = e.target.value
    if (type === 'startDate') {
      setStartDate(newDate)
    } else {
      setEndDate(newDate)
    }
  }

  return (
    <div>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <div>
          <Button
            visibility={id ? true : false}
            className='mr-2'
            to={`${history.location.pathname.split("/").splice(0, 5).join("/")}`}
            size='small'
            color='white'
          >Back to list</Button>
        </div>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }

      <div className='flex items-end justify-between mt-2'>
        <div className={`wght-semibold ${loading ? '' : 'opacity-0'}`}>Loading...</div>
        <div className='flex'>
          <Button
            className='mr-1'
            onClick={() => setAutoRefresh(!autoRefresh)}
            size='small'
            color={autoRefresh ? 'green' : 'white'}
          >Auto refresh</Button>
          <Button
            className='mr-2'
            onClick={() => setSortBy(sortBy === 'occupancy' ? false : 'occupancy')}
            size='small'
            color={sortBy === 'occupancy' ? 'primary' : 'white'}
          >Sort by occupancy</Button>
        </div>
      </div>

      <div className="mt-4">

        {(statsType === 'historical' || statsType === 'purchases') && (
          <div className="flex items-center my-4">
            <span className="mr-2 wght-semibold">Period</span>
            <div>
              <div className="flex flex-col md:flex-row">
                <input
                  name="startDate"
                  className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                  type="date"
                  placeholder="Start date"
                  value={startDate}
                  onChange={(e) => onChangeDate(e, 'startDate')}
                />
                <input
                  name="endDate"
                  className={`rounded px-2 bg-white outline-none placeholder-gray mr-2`}
                  type="date"
                  placeholder="End date"
                  value={endDate}
                  onChange={(e) => onChangeDate(e, 'endDate')}
                />
              </div>
            </div>
          </div>
        )}

        {data?.zonesInfo ? (<div>
          {(!selectedZone && !id) && <div className='p-2 mb-4 text-white border rounded border-grayLighter bg-primary'>
            <h3 className="mb-0 wght-semibold">TOTAL TICKETS (unique)</h3>
            <h3 className="">
              Occupancy:
              <span className="px-2 ml-2 bg-white rounded wght-semibold">
                &nbsp;&nbsp;&nbsp;<span className='text-primary'>{total}</span>
              </span>
            </h3>
          </div>}


          {data.zonesInfo.map((zone, index) => {
            let graphPosition = 0
            const step = Math.round(zone.capacity / 6)
            let marks = []
            let newMark = step || 1
            while (newMark < zone.capacity) {
              marks.push(newMark)
              newMark += step || 1
            }
            const bar = document.querySelector('.occupancyBarGraph')
            if (bar) {
              graphPosition = (zone.occupancy / zone.capacity) * bar.offsetWidth
            }
            const lastChar = history.location.pathname[history.location.pathname.length - 1]
            const url = `${history.location.pathname}${lastChar === '/' ? '' : '/'}${zone._id}`

            if (history.location.pathname)
              return <div key={`${zone._id}-zone-${index}`}>
                <Link className={`block p-2 mb-2 border ${!id && !selectedZone && url ? 'cursor-pointer' : 'cursor-default'} rounded border-grayLighter bg-grayLighter`} to={`${!id && !selectedZone && url || '#'}`}>
                  <h3 className="mb-0 wght-semibold">{zone.title}</h3>
                  <h3 className="">
                    Occupancy:
                    <span className="wght-semibold ">
                      &nbsp;&nbsp;&nbsp;<span className='text-primary'>{zone.occupancy}</span>
                      {zone.capacity > 0 ? ` / ${zone.capacity}` : ''}
                    </span>
                  </h3>
                  {zone.capacity > 0 && (
                    <div className={`w-full mb-2`}>
                      <div className="relative w-full h-4 bg-white rounded">
                        <div
                          className="absolute top-0 left-0 w-full h-4 rounded bg-gradient-to-r from-red-500 occupancyBarGraph"
                          style={{
                            background:
                              'linear-gradient(90deg, rgba(82,238,204,1) 0%, rgba(255,125,87,1) 73%, rgba(187,26,0,1) 100%)',
                            clip: `rect(0, ${graphPosition}px, 1000px, 0)`,
                            transition: 'clip 1s',
                          }}
                        ></div>
                      </div>
                      {statsType === 'live' && (
                        <div className="flex justify-between w-full">
                          <span className="text-xs">0</span>
                          {graphMarks.map((mark, index) => (
                            <span key={`mark-${index}`} className="text-xs">
                              {mark}
                            </span>
                          ))}
                          <span className="text-xs">{zone.capacity}</span>
                        </div>
                      )}
                    </div>
                  )}
                </Link>
              </div>
          })
          }

          {ticketTypesByZone?.length > 1 && <div className='mt-2'>
            <h3 className="mt-4 mb-1 ">Ticket Types in this zone:</h3>
            {ticketTypesByZone.sort(
              (a, b) => {
                if (a.count > b.count) {
                  return -1
                }
                if (a.count < b.count) {
                  return 1
                }
                if (a.ticketType.title > b.ticketType.title) {
                  return 1
                }
                if (a.ticketType.title < b.ticketType.title) {
                  return -1
                }
                return 0
              }
            ).map((ticketTypeInfo, index) => {
              return <div key={`${ticketTypeInfo.ticketType._id}-${index}`} className='p-2 mb-2 border rounded border-grayLighter bg-grayLighter'>
                <span className="mb-0 wght-semibold">{ticketTypeInfo.ticketType.title}:</span>
                <span className="ml-2 mb-">{ticketTypeInfo.count}</span>
              </div>
            })}
          </div>}
        </div>) : <></>}

        {data && data.stats && data.stats.length > 0 ? (
          <div className="overflow-x-scroll">
            {(statsType !== 'purchases') ?
              <table className="w-full text-xs">
                <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                  <tr>
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('date')}
                      >
                        Date
                      </span>
                      {sortedValue === 'date' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    {statsType === 'historical' &&
                      <th className="px-4 py-2">
                        <span
                          className="relative cursor-pointer wght-semibold"
                          onClick={() => handleSort('type')}
                        >
                          Type
                        </span>
                        {sortedValue === 'type' && (
                          <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                        )}
                      </th>
                    }
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('name')}
                      >
                        Name
                      </span>
                      {sortedValue === 'name' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    {!typeEphemerals &&
                      <th className="px-4 py-2">
                        <span
                          className="relative cursor-pointer wght-semibold"
                          onClick={() => handleSort('email')}
                        >
                          Email
                        </span>
                        {sortedValue === 'email' && (
                          <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                        )}
                      </th>
                    }
                    <th className="px-4 py-2">
                      <span className="relative cursor-pointer wght-semibold">
                        {!typeEphemerals ? 'Ticket' : 'Access ID'}
                      </span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedItems &&
                    sortedItems.map((info, index) => (
                      <tr
                        key={`${info._id}-info-${index}`}
                        className={`relative text-left border-b border-grayLight hover:bg-primaryLight
                        ${statsType !== 'historical' ? 'bg-white' : ''}
                        ${(statsType === 'historical' && info.type === 1) ? 'bg-lightGreen' : ''}
                        ${(statsType === 'historical' && info.type === -1) ? 'bg-lightRose' : ''}
                        `}
                      >
                        <th className="px-4 py-2">
                          {dayjs(info.date).format('DD/MM/YYYY HH:mm:ss')}
                        </th>
                        {statsType === 'historical' &&
                          <th className="px-4 py-2">
                            {(info.type === 1) ? 'IN' : 'OUT'}
                          </th>
                        }
                        <th className="px-4 py-2">
                          {!typeEphemerals ?
                            <NavLink
                              className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                              to={`/admin/user/${info.userId}/${info.profileId}`}
                            >
                              {info.firstName || info.lastName
                                ? `${info.firstName} ${info.lastName}`
                                : `${info.profileId}`}
                            </NavLink>
                            : `${info.name}`
                          }
                        </th>
                        {!typeEphemerals &&
                          <th className="px-4 py-2">
                            <NavLink
                              className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                              to={`/admin/user/${info.userId}/${info.profileId}`}
                            >
                              {info.email ? `${info.email}` : `${info.profileId}`}
                            </NavLink>
                          </th>
                        }
                        <th className="px-4 py-2">
                          {!typeEphemerals ?
                            <NavLink
                              className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                              to={`/admin/user/${info.userId}/${info.profileId}/${info.id}`}
                            >
                              View
                            </NavLink>
                            : `${info.id}`
                          }
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
              :
              <table className="w-full text-xs">
                <thead className="w-full text-left bg-white border-b border-grayLight wght-semibold">
                  <tr>
                    <th className="px-4 py-2">
                      {/* // todo change creationDate for updateDate sort */}
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('creationDate')}
                      >
                        Date
                      </span>
                      {sortedValue === 'creationDate' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2">
                      <span className="relative cursor-default wght-semibold">Items</span>
                    </th>
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('totalPrice')}
                      >
                        Total price
                      </span>
                      {sortedValue === 'totalPrice' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('fullName')}
                      >
                        Client
                      </span>
                      {sortedValue === 'fullName' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('age')}
                      >
                        Age
                      </span>
                      {sortedValue === 'age' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2">
                      <span
                        className="relative cursor-pointer wght-semibold"
                        onClick={() => handleSort('employeeFullName')}
                      >
                        Employee
                      </span>
                      {sortedValue === 'employeeFullName' && (
                        <span className="ml-2">{sortAscending ? '↑' : '↓'}</span>
                      )}
                    </th>
                    <th className="px-4 py-2">
                      <span className="relative cursor-default wght-semibold">View</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {sortedItems &&
                    sortedItems.map((info, index) => (
                      <tr
                        key={`${info._id}-info-${index}`}
                        className="relative text-left bg-white border-b border-grayLight hover:bg-primaryLight"
                      >
                        <th className="px-4 py-2">
                          {info.updateDate ?
                            dayjs(info.updateDate).format('DD/MM/YYYY HH:mm:ss')
                            :
                            dayjs(info.creationDate).format('DD/MM/YYYY HH:mm:ss')
                          }
                        </th>
                        <th className="px-4 py-2 whitespace-pre">
                          {info.products}
                        </th>
                        <th className="px-4 py-2">
                          {info.totalPrice}
                        </th>
                        <th className="px-4 py-2">
                          <NavLink
                            className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                            to={`/admin/user/${info.userId}/${info.profileId}`}
                          >
                            {info.fullName
                              ? `${info.fullName}`
                              : `${info.profileId}`}
                          </NavLink>
                        </th>
                        <th className={`px-4 py-2 ${(info.age < 18) ? 'text-error' : ''}`}>
                          {info.age}
                        </th>
                        <th className="px-4 py-2">
                          {info.employee && (
                            info.employee.name
                              ? `${info.employee.name}`
                              : `${info.employee}`
                          )}
                        </th>
                        <th className="px-4 py-2">
                          <NavLink
                            className={`border-solid hover:text-primary border-primary hover:wght-semibold`}
                            to={`/admin/${event}/purchase/${info.id}`}
                          >
                            View
                          </NavLink>
                        </th>
                      </tr>
                    ))}
                </tbody>
              </table>
            }
          </div>
        ) :
          <Loader loading={loading} className="mt-8" text="">
            <></>
          </Loader>
        }

        {(hasMorePages || page > 0) && !loading && (
          <div className="flex justify-between mt-8">
            <div>
              {page > 0 && (
                <span
                  onClick={() => setPage(page - 1)}
                  className="ml-1 cursor-pointer hover:wght-semibold"
                >
                  Previous page
                </span>
              )}
            </div>
            {hasMorePages && (
              <span
                onClick={() => setPage(page + 1)}
                className="mr-1 cursor-pointer hover:wght-semibold"
              >
                Next page
              </span>
            )}
          </div>
        )}
      </div>

    </div>
  )
}

export default StatsEventZone
