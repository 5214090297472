import React from 'react'

import Color from './Color'
import Text from './Text'
import Password from './Password'
import Textarea from './Textarea'
import Rules from './Rules'
import RulesZone from './RulesZone'
import TicketPermissions from './TicketPermissions'
import Requirements from './Requirements'
import Select from './Select'
import SelectDouble from './SelectDouble'
import File from './File'
import Image from './Image'
import Number from './Number'
import Checkbox from './Checkbox'
import Models from './Models'
import Date from './Date'
import Time from './Time'
import Datetime from './Datetime'
import Json from './Json'
import Address from './Address'
import Autogenerated from './Autogenerated'
import Model from './Model'
import Spacer from './Spacer'
import EmailHtml from './EmailHtml'

const components = {
  model: {
    component: Model,
    width: 'full',
  },
  color: {
    component: Color,
    width: '1/2',
  },
  date: {
    component: Date,
    width: '1/2',
  },
  datetime: {
    component: Datetime,
    width: '1/2',
  },
  time: {
    component: Time,
    width: '1/2',
  },
  text: {
    component: Text,
    width: '1/2',
  },
  password: {
    component: Password,
    width: 'full',
  },
  number: {
    component: Number,
    width: '1/2',
  },
  textarea: {
    component: Textarea,
    width: 'full',
  },
  select: {
    component: Select,
    width: '1/2',
  },
  SelectDouble: {
    component: SelectDouble,
    width: 'full',
  },
  file: {
    component: File,
    width: '1/2',
  },
  image: {
    component: Image,
    width: '1/2',
  },
  checkbox: {
    component: Checkbox,
    width: '1/2',
  },
  models: {
    component: Models,
    width: 'full',
  },
  json: {
    component: Json,
    width: 'full',
  },
  address: {
    component: Address,
    width: '1/2',
  },
  rules: {
    component: Rules,
    width: 'full',
  },
  rulesZone: {
    component: RulesZone,
    width: 'full',
  },
  ticketpermissions: {
    component: TicketPermissions,
    width: 'full',
  },
  autogenerated: {
    component: Autogenerated,
    width: '1/2',
  },
  requirements: {
    component: Requirements,
    width: 'full',
  },
  spacer: {
    component: Spacer,
    width: 'full',
  },
  emailHtml: {
    component: EmailHtml,
    width: 'full',
  },
}

const componentWidth = props => {
  if (props.name === 'position') {
    return 'w-full'
  }
  return components[props.type].width
}

const componentClassName = props => {
  let className = `w-full md:pr-4 ${props.parentIsSorting ? 'mb-1 md:mb-2' : 'mb-4 md:mb-8'}`
  className += ` md:w-${componentWidth(
    props,
  )}`
  if (props.type === 'hidden') {
    return 'hidden'
  }
  return className
}

const componentInnerClassName = props => {
  let className = ''
  if (props.name === 'position') {
    className += 'md:w-1/2 md:pr-2'
  }
  return className
}

const Wildcard = props => {
  const TagName = typeof components[props.type] !== 'undefined'
    ? components[props.type].component
    : null
  return TagName ? (
    <div className={componentClassName(props)}>
      <div className={componentInnerClassName(props)}>
        <TagName {...props} />
      </div>
    </div>
  ) : null
}

export default Wildcard
