import React, { useEffect, useState } from 'react'
import defaultValue from '../Lib/defaultValue'
import Cookies from 'js-cookie'
import { useSession } from '../../contexts/AuthContext'


const generateToken = length => {
  const a = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890'.split(
    '',
  )
  const b = []
  for (let i = 0; i < length; i++) {
    const j = (Math.random() * (a.length - 1)).toFixed(0)
    b[i] = a[j]
  }
  return b.join('')
}


const Requirements = ({
  storedData,
  onChange: updateStored,
  name,
  translatable,
  options: { label, model },
  languages,
  models,
  readMode = false,
  innerForm,
}) => {
  const token = useSession()
  const [events, setEvents] = useState([])
  const currentEvent = Cookies.get('event')
  const [cardNumberError, setCardNumberError] = useState(false)
  const [value, setValue] = useState(
    Object.values(defaultValue(name, storedData, translatable, [])).map(
      m => {
        if (typeof m.key === 'undefined') m.key = generateToken(30)
        return m
      },
    ),
  )
    
  useEffect(() => {
    getrequirements()
  }, [])

  useEffect(() => {
    updateStored(name, value)
  }, [value])

  const getrequirements = () => {
    const bodyData = {
      organization: Cookies.get('organization'),
    }    

    fetch(`${process.env.REACT_APP_API_URL}tickettype-requirements`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response.json().then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          let items = response.body.items
          setEvents(items)
        } else {
          console.log(response.body)
        }
      })
  }

  const removeItem = (id, key) => {
    const newValue = [...value]
    newValue[key].ticketTypes = newValue[key].ticketTypes.filter(i => i !== id) || []
    setValue(newValue)
  }

  const multipleOption = ({ events, item, id, index, key }) => {
    const selectedEvent = events.find(i => i._id === item.event)
    if (selectedEvent) {
      const option = selectedEvent.tickettypes.find(i => i._id === id)
      return (
        <div
          key={`${id}${index}`}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${id}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(id, key)}>
            ✕
          </span>
        </div>
      )
    }
  }

  const validateCardNumber = (cardNumber) => {
    const regex = /^([0-9,]*)$/
    const isValid = regex.test(cardNumber)
    setCardNumberError(!isValid)
  }

  const ListItem = ({ item, key }) => (
    <div className={`w-full relative px-4 py-2 my-1 ${innerForm ? 'bg-white' : 'bg-grayLight'}`} key={item.key}>
      <div className="flex w-full mb-4">
        <div className="flex flex-col pr-4">
          <p className="pr-4">Type</p>
          <select
            disabled={readMode}
            name="type"
            type="text"
            value={item.type}
            onChange={(e) => onChange(e, item.key)}
            className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray  ${innerForm ? 'bg-grayLight' : 'bg-white'}`}
          >
            <option value="-1" className=' text-red'>Select type</option>
            <option value="has">Has</option>
            <option value="hasNot">Has not</option> 
            <option value="validated">Validated</option>
            <option value="oneToQuantity">1-Quantity</option>
            <option value="cardNumber">Card Number</option>
          </select>
        </div>
        {
          item.type != 'cardNumber' &&
            <div className="flex flex-col pr-4">
              <p className="pr-4">Event</p>
              <select
                disabled={readMode}
                name="event"
                type="text"
                value={item.event || currentEvent}
                onChange={(e) => onChange(e, item.key)}
                className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray  ${innerForm ? 'bg-grayLight' : 'bg-white'}`}
              >
                <option value="-1" className=' text-red'>Select event</option>
                {
                  events.map((event, index) => (
                    <option key={index} value={event._id}>{event.title}</option>
                  ))
                }
              </select>
            </div>
        }
        {
          item.type === 'cardNumber' &&
            <div className="flex flex-col pr-4">
              <p className="pr-4">Card first digits separated by commas</p>
              <input
                name='cardNumber'
                type="text"
                placeholder="Card Number"
                value={item.cardNumber}
                className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray  ${innerForm ? 'bg-grayLight' : 'bg-white'} ${cardNumberError ? 'border border-error' : ''}`}
                onChange={(e) => {
                  onChange(e, item.key)
                  validateCardNumber(e.target.value)
                }}
                disabled={readMode}
              />
              { cardNumberError &&
                <span className='absolute bottom-0 mb-1 text-xs text-error'>Invalid format</span>
              }
            </div>
        }
        {
          item.type === 'oneToQuantity' && 
            <div className="flex flex-col pr-4">
              <p className="pr-4">Quantity</p>
              <input
                name='quantity'
                type="number"
                placeholder="Quantity"
                value={item.quantity}
                className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray  ${innerForm ? 'bg-grayLight' : 'bg-white'}`}
                onChange={(e) => onChange(e, item.key)}
                disabled={readMode}
              />
            </div>
        }
        <span className="pl-1 ml-auto cursor-pointer" onClick={() => removeRequirement(item.key)}>
          ✕
        </span>
      </div>
      <div className="flex flex-col pr-4">
      {
          item.type != 'cardNumber' &&
          <div className='mb-2'>
            <p className="pr-4">Ticket Type</p>
            <select
              disabled={readMode}
              name="ticketTypes"
              type="text"
              value={-1}
              onChange={(e) => onChange(e, item.key)}
              className={`h-full w-full rounded px-2 py-1 outline-none placeholder-gray  ${innerForm ? 'bg-grayLight' : 'bg-white'}`}
            >
              <option value="-1" className=' text-red'>Select ticket type</option>
              {
                events.map((event) => {
                  if (event._id === item.event) {
                    return event.tickettypes.map((ticketType, index) => (
                      <option
                        key={index}
                        value={ticketType._id}
                        disabled={item.ticketTypes?.length && item.ticketTypes?.find((item) => item === ticketType._id)}
                      >
                        {ticketType.title}
                      </option>
                    ))
                  }
                })
              }
            </select>
            {
              item.ticketTypes?.length > 0 && (
                <div className="flex flex-wrap mt-2">
                  {item.ticketTypes.map((id, index) => id.length > 0 && multipleOption({ id, index, item, events, key}))}
                </div>
              )
            }
          </div>
      }
      </div>
    </div>
  )

  const onChange = (event, key) => {
    const rawValue = [...value]
    rawValue.map(item => {
      if (item.key === key) {
        if (event.target.name === 'ticketTypes') {
          if (!item.ticketTypes || !item.ticketTypes?.length) item.ticketTypes = []
          if (item.ticketTypes.includes(event.target.value)) {
            item.ticketTypes = item.ticketTypes.filter((item) => item !== event.target.value)
          } else {
            item.ticketTypes.push(event.target.value)
          }
        } else {
          item[event.target.name] = event.target.value
          if (event.target.name === 'event') item.ticketTypes = []
        }
      }
      return item
    })
    setValue(rawValue)
  }

  const removeRequirement = (key) => {
    if (readMode) return
    const rawValue = [...value]
    setValue(rawValue.filter(item => item.key !== key))
  }

  const List = ({ items }) => {
    const renderItems = []
    items.map((item, index) => {
      return renderItems.push(ListItem({key: index, item: item} ))
    })
    return renderItems
  }

  const addModel = async () => {
    if (readMode) return
    setValue([
      ...value,
      {
        key: generateToken(30),
        event: currentEvent || '-1',
      },
    ])
  }

  return (
    <div>
      <div className="flex flex-row items-center mb-2">
        <label>{label}</label>
        <span
          onClick={() => addModel()}
          className="px-2 py-1 ml-2 text-xs rounded cursor-pointer text-primaryLighter bg-primary hover:wght-semibold"
        >
          <span>Add</span>
        </span>
      </div>
      {List({ items: value })}
    </div>
  )
}

export default Requirements
