import React, { useState, useEffect } from 'react'
import LanguageSelector from './LanguageSelector'
import Errors from './Errors'
import defaultValue from '../Lib/defaultValue'
import Cookies from 'js-cookie'


const Select = ({
  storedData,
  showErrors,
  onChange,
  name,
  translatable,
  options: {
    type, label, enableSelectedOptions, defaultOption, model, multiple, options: staticOptions, allowDuplicates, readOnly
  },
  validations,
  languages,
  find,
  innerForm,
  readMode,
}) => {
  const [language, setLanguage] = useState(
    translatable ? Object.keys(languages)[0] : null,
  )
  const [options, setOptions] = useState([{ _id: -1, title: label }])

  const [value, setValue] = useState(
    defaultValue(name, storedData, translatable, multiple ? [] : -1),
  )

  const [errors, setErrors] = useState([])
  const [currentValue, setCurrentValue] = useState(multiple ? [] : -1)

  const shouldAddMultipleValue = (val, v) => {
    if (!val.includes(v) || allowDuplicates) {
      val.push(v)
    }
    return [...val]
  }

  const onChangeText = (e) => {
    if (translatable) {
      value[language] = multiple
        ? shouldAddMultipleValue(value[language], e.target.value)
        : e.target.value
      setValue({ ...value })
    } else {
      setValue(
        multiple
          ? shouldAddMultipleValue(value, e.target.value)
          : e.target.value,
      )
    }
  }

  const removeItem = (id, index) => {
    if (readMode || readOnly) return
    if (translatable) {
      if (allowDuplicates) {
        value[language].splice(index, 1)
        value[language] = value[language]
      } else {
        value[language] = value[language].filter(item => item !== id)
      }
      setValue({ ...value })
    } else {
      if (allowDuplicates) {
        value.splice(index, 1)
        setValue([...value])
      } else {
        setValue([...value.filter(item => item !== id)])
      }
    }
  }

  useEffect(() => {
    setCurrentValue(translatable ? value[language] || [] : value)
  }, [value, language, translatable])


  const checkIfDefaultOption = (options, defaultOption) => {
    const defaultOptionInOptions = options.find(options => options._id === defaultOption)
    if (defaultOptionInOptions) return defaultOptionInOptions._id
    return -1
  }

  useEffect(() => {
    if (type === 'static') {
      setOptions([...Object.keys(staticOptions).map(key => ({
        _id: key,
        title: staticOptions[key],
      }))])
    } else {
      setOptions([{ _id: -1, title: 'Loading...' }])

      if (typeof find[model] !== 'undefined') {
        setOptions([...find[model]])

        if (model === 'TicketType' && storedData.modelName === "Ticket") {
          const checkIfExistSelectedTicketType = find[model].some(ticketType => value === ticketType._id)
          const newValue = checkIfExistSelectedTicketType ? value : -1
          setValue(newValue)
        }

        if (model === 'Organization' && (value === -1 || value.length === 0)) {
          const currentOrganization = find[model].find(organization => organization._id === Cookies.get('organization'))
          if (currentOrganization) {
            const newValue = multiple ? [currentOrganization._id] : currentOrganization._id
            setValue(newValue)
          }
        }

        if (model === 'Event' && (value === -1 || value.length === 0)) {
          const currentEvent = find[model].find(event => event._id === Cookies.get('event'))
          if (currentEvent) {
            const newValue = multiple ? [currentEvent._id] : currentEvent._id
            setValue(newValue)
          }
        }
      }
    }
  }, [name, type, staticOptions, find])

  useEffect(() => {
    const errs = Errors(value, validations, translatable, languages)
    onChange(name, value, errs)
    setErrors(errs)
  }, [value])

  useEffect(() => {
    if (defaultOption && !storedData?._id) {
      const parsedDefaultOption = checkIfDefaultOption(options, defaultOption)
      setValue(parsedDefaultOption)
      setCurrentValue(parsedDefaultOption)
    }
  }, [options])

  const multipleOption = (id, index) => {
    const option = options.find(item => item._id === id)
    if (model === 'Event' && !option) {
      return false
    } else {
      return (
        <div
          key={`${id}${index}`}
          className="px-1 mb-1 mr-1 text-xs border border-black rounded grow-0 shrink-0"
        >
          {option ? option.title : `Not found ${id}`}
          <span className="pl-1 cursor-pointer" onClick={() => removeItem(id, index)}>
            ✕
          </span>
        </div>
      )
    }
  }

  const checkIfdisabledOption = (option) => {
    if (typeof enableSelectedOptions === "boolean") return !enableSelectedOptions
    if (typeof option.disabled === "boolean") return option.disabled
    return multiple && currentValue.includes(option._id) && !allowDuplicates
  }

  const select = (
    <div>
      <div className="relative">
        <div className="absolute right-0 py-1 mr-2">&#8595;</div>
        <select
          name={name}
          className={`w-full px-2 py-1 ${innerForm ? 'bg-white' : 'bg-grayLight'} ${showErrors && errors.length > 0 ? 'border border-error' : ''
            } rounded ${translatable && Object.keys(languages)[0] === language
              ? 'rounded-tl-none'
              : ''
            } outline-none appearance-none`}
          type="text"
          placeholder={label}
          value={multiple ? -1 : currentValue}
          onChange={onChangeText}
          disabled={readMode || readOnly}
        >
          <option disabled={validations && !!validations['not_blank']} value={-1}>Select {label}</option>
          {options.map((option, index) => (
            <option
              key={`${option._id}${index}`}
              disabled={checkIfdisabledOption(option)}
              value={option._id}
            >
              {option.ref || option.title || option.slug}
            </option>
          ))}
        </select>
      </div>
      {
        multiple && currentValue.length > 0 && options.length > 0 && (
          <div className="flex flex-wrap mt-2">
            {currentValue
              .map((id, index) => id.length > 0 && multipleOption(id, index))}
          </div>
        )
      }
    </div >
  )

  return (
    <div>
      <label>{label}</label>
      {translatable ? (
        <LanguageSelector
          language={language}
          languages={languages}
          onChangeLanguage={v => setLanguage(v)}
        >
          {select}
        </LanguageSelector>
      ) : (
        select
      )}
      {showErrors && errors.length > 0 &&
        <p className="text-error">{errors[0]}</p>
      }
    </div>
  )
}

export default Select
