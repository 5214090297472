import React, { useState, useEffect, useRef } from 'react'
import Cookies from 'js-cookie'
import Button from './Button'
import debounce from 'lodash/debounce'

const InvitationModal = ({ handleShowModal, modelName, event, selectedOrganization, token }) => {
  const [permission, setPermission] = useState('admin')
  const [inputEmails, setInputEmails] = useState('')
  const [loading, setLoading] = useState(false)
  const [url, setUrl] = useState(null)
  const [textButton, setTextButton] = useState('Add')
  const [suggestedEmails, setSuggestedEmails] = useState([])
  const [loadingEmails, setLoadingEmails] = useState(false)
  const debounced = useRef(debounce((fn, value) => fn(value), 300))
  const [allowSendInvitation, setAllowSendInvitation] = useState(true)
  const [succefullMessage, setSuccefullMessage] = useState()
  const [errorMessage, setErrorMessage] = useState()

  const emailValidation = (inputEmails) => {
    const removedSpaceEmails = inputEmails.replace(/\s/g, '')
    const arrayEmails = removedSpaceEmails.split(',')

    const filterValidEmails = arrayEmails.filter(email => {
      const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if (regex.test(email)) return email;
    });

    return filterValidEmails
  }

  const handleKeyDown = ({ code }) => {
    if (code === "Escape") handleShowModal()
  }

  const sendMailInvitation = async () => {
    setLoading(true)
    const emails = emailValidation(inputEmails)
    if (!emails.length) {
      setLoading(false)
      return alert('Email is required')
    }

    fetch(`${process.env.REACT_APP_API_URL}send-invitation-mail`, {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        permission,
        emails,
        url,
        organization: selectedOrganization || Cookies.get('organization'),
        event,
        modelName,
      }),
    })
      .then((response) => response.json())
      .then((response) => {
        setTextButton('Close')
        setLoading(false)
        if (response.status === 200) {
          setSuccefullMessage(`Invitations sent successfully ${response.message}`)
        } else {
          setErrorMessage(`Something went wrong: ${response.message}`)
        }
      })
      .catch((e) => {
        console.log('e', e)
        setLoading(false)
        setErrorMessage('Something went wrong')
      })
  }

  const handlePermission = (permission) => {
    setPermission(permission)
  }

  const handleEmail = (currentEmails) => {
    setInputEmails(currentEmails)
  }

  useEffect(() => {
    if (!['Organization', 'Event', 'GuestsEvents'].includes(modelName) && (!event || event === 'all')) {
      setAllowSendInvitation(false)
    }

    setUrl(`${window.location.href}?organization=${selectedOrganization || Cookies.get('organization')}&event=${event}`)
    document.addEventListener("keydown", handleKeyDown)

    return () => document.removeEventListener("keydown", handleKeyDown)
  }, [])

  const getEmails = (inputEmail) => {
    if (!inputEmail) return setSuggestedEmails([])
    setLoadingEmails(true)

    fetch(`${process.env.REACT_APP_API_URL}search-user-email`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({
        email: inputEmail.replace(/[^a-zA-Z0-9.@]/g, "")
      }),
    })
      .then((response) =>
        response
          .json()
          .then((data) => ({ status: response.status, body: data }))
      )
      .then((response) => {
        setLoadingEmails(false)
        setSuggestedEmails(response.body.emails)
      })
  }

  useEffect(() => {
    if (inputEmails.length) {
      const removedSpaceEmails = inputEmails.replace(/\s/g, '')
      const arrayEmails = removedSpaceEmails.split(',')

      debounced.current(getEmails, arrayEmails[arrayEmails.length - 1])
    } else {
      setSuggestedEmails([])
    }
  }, [inputEmails])

  const handleInserEmail = (email) => {
    const emails = emailValidation(inputEmails)

    if (emails.length > 0) {
      return setInputEmails(`${emails.toString()},${email},`)
    }

    setInputEmails(`${email},`)
  }

  const handleCloseSuggestedEmails = ({ key }) => {
    if (key === "Escape") setSuggestedEmails([])
  }

  return (
    <div
      className='fixed top-0 right-0 z-40 flex items-center justify-center w-full h-full'
      onClick={() => setSuggestedEmails([])}
    >
      <div className='absolute top-0 left-0 w-full h-full bg-black/5' onClick={handleShowModal}></div>
      <div className="inline-flex flex-col w-full p-4 m-2 bg-white border rounded sm:m-0 sm:w-2/3 md:w-1/2 z-1" style={{ maxWidth: 500 }}>
        <h2 className="text-lg wght-semibold">Add people</h2>

        {!allowSendInvitation && (
          <p className="text-sm text-error">
            You must select an event from left menu to invite users to this zone.
          </p>
        )}

        {(modelName !== 'Event' || (event && event !== 'all')) && (
          <>
            <form className="inline-flex flex-col">
              <div className="inline-flex flex-col mt-4 mb-2">
                <label className="mb-1 text-sm" htmlFor='email'>Emails</label>
                <input
                  disabled={loading || !allowSendInvitation}
                  name="email"
                  id="email"
                  className={`rounded px-2 bg-grayLight outline-none placeholder-gray w-full py-1 text-sm`}
                  type="text"
                  placeholder="Email address"
                  onChange={(e) => handleEmail(e.target.value)}
                  value={inputEmails}
                  onKeyDown={(e) => handleCloseSuggestedEmails(e)}
                />
                {
                  suggestedEmails.length > 0 && !loadingEmails &&
                  <div className='relative flex' >
                    <div className='absolute top-0 left-0 flex flex-col w-full overflow-y-scroll bg-grayLight max-h-100px'>
                      <span className='px-2 pt-2 border-t opacity-50 text-xxs'>Organization users:</span>
                      {suggestedEmails.map((email, index) =>
                        <span key={index} className='w-full pl-2 my-1 mr-4 text-sm cursor-pointer hover:text-primary hover:wght-semibold' onClick={() => handleInserEmail(email)}>{email}</span>
                      )}
                    </div>
                  </div>
                }
                {
                  inputEmails.length > 0 && loadingEmails &&
                  <div className='relative felx'>
                    < div className='absolute top-0 left-0 flex flex-col w-full rounded bg-grayLight'>
                      <span className='w-full pl-2 my-2 text-sm'>loading...</span>
                    </div>
                  </div>
                }
              </div>

              <div className="inline-flex flex-col mt-2 mb-4 md:w-1/2">
                <span className="text-sm">Role</span>

                <select
                  disabled={loading || !allowSendInvitation}
                  name="exchanged"
                  type="text"
                  onChange={(e) => handlePermission(e.target.value)}
                  className={`bg-grayLight outline-none placeholder-gray py-1 mb-1 text-sm block rounded`}
                  selected="admin"
                >
                  <option className="h-8 text-black" value="admin">
                    Admin
                  </option>
                  <option className="h-8 text-black" value="write">
                    Write
                  </option>
                  <option className="h-8 text-black" value="read">
                    Read
                  </option>
                </select>
              </div>
            </form>

            <div className="flex flex-wrap items-end justify-end md:my-1">
              {succefullMessage && (
                <div className="flex items-center w-full mb-2">
                  <span className="text-xs text-success">{succefullMessage}</span>
                </div>
              )}
              {errorMessage && (
                <div className="flex items-center w-full mb-2">
                  <span className="text-xs text-error">{errorMessage}</span>
                </div>
              )}
              <div>
                <div className="flex justify-end">
                  {!loading && textButton === 'Add' &&
                    <Button
                      size="small"
                      color="cancel"
                      disable={loading}
                      onClick={handleShowModal}
                      className="mr-1"
                    >
                      Cancel
                    </Button>
                  }

                  {allowSendInvitation &&
                    <Button
                      disable={loading || !allowSendInvitation}
                      onClick={textButton === 'Add' ? sendMailInvitation : handleShowModal}
                      className="px-4 py-1 text-xs "
                      color={textButton === 'Add' ? 'primary' : 'success'}
                    >
                      {loading ? 'Loading...' : textButton}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </>
        )}
      </div >
    </div >
  )
}

export default InvitationModal
