import React, { useState } from 'react'
import Cookies from 'js-cookie'
import { useHistory, useParams } from 'react-router-dom'
import useSessionPermissions from '../../hooks/useSessionPermissions'
import { useSession } from '../../contexts/AuthContext'
import useOrganizationInfo from '../../hooks/useOrganizationInfo'

const ImportTicketsPagePage = () => {
  const { event } = useParams()
  const { getEvent, getOrganization } = useOrganizationInfo(event)
  const history = useHistory()
  const [currentFile, setCurrentFile] = useState()
  const { role, loading } = useSessionPermissions('ticketType', 'ticketType')
  const token = useSession()

  const [errorMessage, setErrorMessage] = useState()

  const handleChange = (e) => {
    setCurrentFile(e.target.files[0])
  }

  const upload = (event) => {
    event.preventDefault()
    const formData = new FormData()
    formData.append('file', currentFile)
    const filters = {
      organization: getOrganization(),
      event: getEvent()
    }
    formData.append('filters', JSON.stringify(filters))

    fetch(`${process.env.REACT_APP_API_URL}import-tickets`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: formData
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          history.push('/admin/list/Ticket')
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('user')
            history.push('/login')
          } else if (response.status === 401) {
            history.push(`/admin/no-access?url=${window.location.pathname}`)
          }
          setErrorMessage(response.body.error)
          setTimeout(() => {
            setErrorMessage()
          }, 4000);
        }
      })
  }

  return (
    <div>
      <h1 className="text-xl wght-semibold">Import Tickets</h1>
      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }
      {loading && <div className="mt-4">Loading...</div>}
      {!loading &&
        <>
          <p className="mt-4">Select a CSV file with the columns:</p>
          <p className="mb-4 font-mono text-xs">ticketType, ticketId, ticketInfo</p>
          <form className="mt-4">
            <div className="mb-2">
              <input onChange={handleChange} type="file" name="file" />
            </div>
            {currentFile && <button onClick={upload} className="px-2 py-1 text-xs rounded cursor-pointer text-primaryLighter bg-primary hover:wght-semibold">Upload</button>}
          </form>
          {errorMessage && <p className="mt-4 text-red">Error: {errorMessage}</p>}
        </>
      }
    </div>
  )
}

export default ImportTicketsPagePage