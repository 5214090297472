import React, { useState, useEffect, useCallback } from 'react'
import { useParams, useHistory, useLocation, Link } from 'react-router-dom'
import Cookies from 'js-cookie'
import { Helmet } from 'react-helmet'
import QRCode from 'qrcode.react'
import Form from './Form/Form'
import flatten from './Lib/flatten'
import { useSession } from '../contexts/AuthContext'
import { redirectGuestsURL } from './Lib/guestsUtils'

const modelsWithoutList = []


const EditGuest = ({ selectedMenuEvent, setSelectedMenuEvent, menuEventsOptions }) => {
  const history = useHistory()
  const location = useLocation()
  const {
    name: modelName,
    id: urlId,
    GuestsEvent,
    GuestsCategory,
    GuestsEntity,
    GuestsQuota,
    GuestsInvitation,
  } = useParams()
  const [id, setId] = useState(urlId)
  const [data, setData] = useState(false)
  const [originalData, setOriginalData] = useState(false)
  const [name, setName] = useState(modelName)
  const [title, setTitle] = useState(name)
  const [showErrors, setShowErrors] = useState(false)
  const [errors, setErrors] = useState({})
  const [saving, setSaving] = useState(false)
  const [saved, setSaved] = useState(false)
  const [notUniqueId, setNotUniqueId] = useState(false)
  const [role, setRole] = useState(false)
  const token = useSession()
  const editIndex = location.pathname.indexOf('/edit');
  const pathname = location.pathname.substring(0, editIndex);
  const page = location.state?.page || 0

  const getParsedPathname = useCallback(
    () => {
      return history.location.pathname
    },
    [page, history.location.pathname],
  )

  const updateStored = (key, value, errs = []) => {
    setNotUniqueId()

    if (!data.stored) {
      data.stored = {}
    }

    if (typeof data.stored.modelName === 'undefined') {
      data.stored.modelName = name
    }

    data.stored[key] = key === 'email' ? value.trim() : value
    errors[key] = errs
    setData({ ...data })
    setErrors({ ...errors })
  }

  const getData = () => {
    setData(false)
    setOriginalData(false)
    setName(modelName)

    const bodyData = {
      modelName,
      filters: {
        organization: Cookies.get('organization'),
        event: GuestsEvent || Cookies.get('event'),
      },
      url: getParsedPathname(),
      guestsquota: GuestsQuota,
    }

    if (Cookies.get('userManagerPreferences')) {
      bodyData.userManagerPreferences = JSON.parse(Cookies.get('userManagerPreferences')).guestsFilters
    }

    bodyData.defaultSelectedOptions = {
      GuestsEvent,
      GuestsCategory,
      GuestsEntity,
      GuestsQuota,
      GuestsInvitation,
    }

    if (id) {
      bodyData._id = id
    }

    fetch(`${process.env.REACT_APP_API_URL}model-edit`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.status === 200) {
          setRole(response.body.role)
          setTitle(`${id ? 'Edit' : 'Create'} ${response.body.modelConfig.title}`)
          setData(response.body)
          if (response.body?.stored) {
            setOriginalData(JSON.parse(JSON.stringify(response.body?.stored)))
          }
        } else {
          console.log('Error', response.status, response.body.error)
          if (response.status === 403) {
            Cookies.remove('token')
            history.push('/login')
          } else if (response.status === 401) {
            window.location.replace(`/admin/no-access?url=/admin/guests/list/${modelName}`);
          }
        }
      })
  }

  useEffect(() => {
    getData()
  }, [modelName, id])

  useEffect(() => {
    if (
      GuestsEvent &&
      menuEventsOptions.length &&
      (!selectedMenuEvent || selectedMenuEvent.slug !== GuestsEvent)
    ) {
      redirectGuestsURL({
        GuestsEvent,
        menuEventsOptions,
        setSelectedMenuEvent,
        getData,
        endpoint: 'model-edit',
        token,
        modelName,
        history,
      })
    }
  }, [menuEventsOptions])

  const orderModels = function (data) {
    Object.keys(data).forEach(key => {
      const staticElement = data[key]
      if (Array.isArray(staticElement)) {
        staticElement.forEach((jsonElement, key2) => {
          if (jsonElement['items']) {
            data[key][key2]['items'] = jsonElement['items'].sort((a, b) => a.position - b.position)
          }
        });
      }
    })
  }

  const checkIfTicketIdIsUnique = async (data, ticketModel) => {
    if (!ticketModel) return false
    const bodyData = { ...data, url: getParsedPathname() }

    let isUnique = false
    await fetch(`${process.env.REACT_APP_API_URL}ticket-unique`, {
      body: JSON.stringify(bodyData),
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => response
        .json()
        .then(data => ({ status: response.status, body: data })))
      .then(response => {
        if (response.body.unique) {
          isUnique = true
        }
      })
      .catch((err) => console.log(err))
    return isUnique
  }

  const save = async (type = 'default', callback = false) => {
    const errs = flatten(errors)
    if (Object.keys(flatten(errs)).length > 0) {
      setShowErrors(true)
    } else {
      setShowErrors(false)
      setSaving(true)
      orderModels(data.stored)

      const bodyData = {
        ...data.stored,
        modelName,
        url: getParsedPathname(),
        guestsInfo: {
          GuestsEvent,
          GuestsEntity,
          GuestsQuota,
        },
        filters: {
          organization: Cookies.get('organization'),
          event: GuestsEvent || Cookies.get('event'),
        },
        originalData,
      }

      const ticketModel = (modelName === 'Ticket')
      const uniqueTicketId = await checkIfTicketIdIsUnique(data.stored, ticketModel)
      if (!ticketModel || uniqueTicketId) {
        fetch(`${process.env.REACT_APP_API_URL}model-update`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(bodyData),
        })
          .then(response => response
            .json()
            .then(data => ({ status: response.status, body: data })))
          .then(response => {
            setSaving(false)
            if (callback) callback(response)
            if (response.status === 200) {
              if (data.stored) {
                setOriginalData(JSON.parse(JSON.stringify(data.stored)))
              }
              if (type === 'default') {
                if (typeof id === 'undefined') {
                  setId(response.body._id)
                  history.push(`${pathname}/edit/${modelName}/${response.body._id}`)
                }
                setSaved(true)
                setTimeout(() => setSaved(false), 3000)
                return
              } else if (type === 'save-and-another') {
                setId(null)
                history.push(`${pathname}/edit/${modelName}`)
                history.go(0)
                return
              } else if (type === 'save-and-close') {
                if (modelsWithoutList.includes(modelName)) {
                  history.push(`${pathname}/1`)
                } else {
                  history.push(page > 0 ? `${pathname}/${page + 1}` : pathname)
                }
              }
            } else if (response.status === 400) {
              switch (response.body.error) {
                case 'quota_exceeded':
                  alert("Quota exceeded, can't create more invitations")
                  break;
                case 'ticket_not_created':
                  alert("Ticket not created, try again")
                  break;
                case 'invitation_duplicated':
                  alert("Invitation already exists with this email and same ticket type. Change email or ticket type to proceed.")
                  break;
              }
            } else {
              console.log('Error', response.status, response.body.error)
              if (response.status === 403) {
                Cookies.remove('token')
                history.push('/login')
              } else if (response.status === 401) {
                history.push(`/admin/no-access?url=${window.location.pathname}`)
              }
            }
          })
          .catch(() => setSaving(false))
      } else {
        setSaving(false)
        if (!uniqueTicketId) {
          setNotUniqueId(true)
        }
      }
    }
  }

  return (
    <div>
      <Helmet>
        <title>
          {title}
        </title>
      </Helmet>
      <div className="flex items-center justify-between">
        <h2 className="text-xl wght-semibold">{title}</h2>
        <Link
          to={page > 0 ? `${pathname}/${page + 1}` : pathname}
          className="cursor-pointer hover:wght-semibold hover:text-primary"
        >
          Cancel
        </Link>
      </div>

      {
        role &&
        <div className='flex'>
          <div className="relative flex text-xs text-gray wght-light">
            <div>
              <span className='wght-semibold'>{role.toUpperCase()}</span>
            </div>
          </div>
        </div>
      }


      {!data && <div className="mt-8 mb-8">Loading...</div>}

      {data && data.stored && data.stored.deleted &&
        <div className='p-2 mt-8 mb-8 text-white rounded bg-error'>
          <span>This {modelName} is deleted</span>
        </div>
      }

      {data && modelName === name && (
        <div className={`mt-8 ${data && data.stored && data.stored.deleted ? 'opacity-60 select-none pointer-events-none' : ''}`}>
          {
            data?.stored?.ticket &&
            <div className="flex justify-end w-full mb-2">
              <Link
                className="mr-4 cursor-pointer text-primary hover:text-primary hover:wght-semibold"
                to={{ pathname: `/admin/${GuestsEvent || 'all'}/edit/Ticket/${data?.stored?.ticket}` }}
              >
                View ticket detail
              </Link>
            </div>
          }
          <Form
            onChange={updateStored}
            data={data}
            name={name}
            showErrors={showErrors}
            mode={id ? 'edit' : 'create'}
            currentData={data}
            readMode={role === 'read'}
          />
          {modelName === 'Ticket' && data && data.stored && data.stored.ticketId && <div className="p-4 bg-white border-t border-grayLight"><QRCode size={300} value={`${data.stored.ticketId}`} /><span>{data.stored.ticketId}</span></div>}

          {data && data.stored && !data.stored.deleted &&
            <div className="sticky bottom-0 flex justify-between p-4 text-xs bg-white border-t border-grayLight">
              <div>
                {saving ? (
                  <span className="text-black wght-bold">Saving...</span>
                ) : (
                  <span
                    onClick={() => save()}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &#8599;{' '}
                  </span>
                )}
                {saved && <span className="ml-4 text-black">Saved successfully!</span>}
                {modelName === 'Ticket' && notUniqueId && <span className="pl-2 text-red">Ticket ID must be unique</span>}
              </div>
              <div>
                {saving ? null : (
                  <span
                    onClick={() => save('save-and-another')}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &amp; create &#8599;{' '}
                  </span>
                )}
              </div>
              <div>
                {saving ? null : (
                  <span
                    onClick={() => save('save-and-close')}
                    className="px-2 py-1 cursor-pointer wght-normal hover:wght-semibold hover:text-primary"
                  >
                    Save &amp; close &#8599;
                  </span>
                )}
              </div>
            </div>
          }
        </div>
      )}
    </div>
  )
}

export default EditGuest
